@import '../../../shared/_variables';

.trip__row {
  width: 100%;

  @media screen and (min-width: $b-desktop) {
    display: flex;
    margin: 30px auto;
  }

  &.align-top {
    align-items: flex-start;
  }
}

.trip__information {
  background-color: $c-solitude;
  padding: 20px;
}

.trip__information-column {
  text-align: center;
  margin: 30px 0;

  @media screen and (min-width: $b-desktop) {
    flex: 1;
    margin: 0;
    text-align: left;
  }
}

.trip__information-title {
  font-weight: bold;
  max-width: 300px;
  margin: 30px auto 10px;
}

.trip__information-cta {
  max-width: 300px;
  margin: 0 auto;
}

.trip__information-link {
  color: $c-pacific;
  font-weight: bold;
  font-size: $f-large;
}

.trip__header {
  background-color: $c-gallery;
  border-radius: $s-radius;
  padding: $s-margin;
  font-weight: bold;

  @media screen and (min-width: $b-desktop) {
    font-size: 18px;
  }
  //margin: 30px -#{$s-margin} $s-margin -#{$s-margin}

  a {
    float: right;
  }
}