@import '../../../shared/_variables';
@import '../../../shared/_icons';

// TODO: Merge with tripHero? But with centered text?
.location-l__hero {
  background-position: center;
  background-size: cover;
  position: relative;
  color: $c-white;
  padding: 30px 0;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  > div {
    position: relative;
    text-shadow: 0 0.5px 2px rgba(0,0,0,.8);
  }

  @media screen and (min-width: $b-desktop) {
    order: -1;
    padding: 50px 0;
  }
}

.location-l__contact {
  background-color: $c-solitude;
  padding: 20px 50px;
  margin: -20px auto 20px;
  max-width: 320px;
  text-align: center;
  position: relative;

  @media screen and (min-width: $b-desktop) {
    max-width: 500px;
    padding: 40px 50px;
  }
}

.location-l__contact-title {
  font-weight: bold;
  font-size: $f-large;
  margin-bottom: 20px;
}

.location-l__contact-phone {
  color: $c-pacific;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  font-size: $f-large;
}

.location-l__contact-email {
  display: block;
  margin-bottom: $s-margin;
}

.location-l__leaders {
  display: flex;
  flex-wrap: wrap;
}

.location-l__leader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  flex: 1 1 50%;

  @media screen and (min-width: $b-desktop) {
    flex: 1 1 20%;
  }
}

.location-l__leader-image {
  height: 120px;
  width: 140px;
  padding: 5px 15px;

  @media screen and (min-width: $b-desktop) {
    height: 90px;
    width: 100px;
    padding: 10px 15px;
  }
}

.location-l__leader-file {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $c-gallery;
}

.location-l__leader-name {
  font-weight: bold;
}

.location-l__leader-leader {
  font-weight: bold;
  font-size: $f-small;
  color: $c-mine;
  text-transform: uppercase;
}

.location-l__back {
  font-weight: bold;
  text-decoration: none;
  color: $c-lochmara;
  margin-bottom: 20px;
  display: block;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.5;
  }

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 16px;
    width: 16px;
    margin-right: 5px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: icon('caret', $c-black);
    transform: rotate(180deg);
  }
}

.location-l__leader-language {
  width: 26px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border: 1px solid $c-dusty;

  & + & {
    margin-left: 5px;
  }

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 2px;
  }

  &__se {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgwIiB3aWR0aD0iNjQwIiB2aWV3Qm94PSIwIDAgNjQwIDQ4MCI+CiAgPGRlZnM+CiAgICA8Y2xpcFBhdGggaWQ9ImEiPgogICAgICA8cGF0aCBmaWxsLW9wYWNpdHk9Ii42NyIgZD0iTS01My40MjEgMGg2ODIuNjd2NTEyaC02ODIuNjd6Ii8+CiAgICA8L2NsaXBQYXRoPgogIDwvZGVmcz4KICA8ZyBjbGlwLXBhdGg9InVybCgjYSkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUwLjA4Mikgc2NhbGUoLjkzNzUpIj4KICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLXdpZHRoPSIxcHQiPgogICAgICA8cGF0aCBmaWxsPSIjMDA2YWE3IiBkPSJNLTEyMS4xMDMuMzAyaDI1NlYyMDUuMWgtMjU2ek0tMTIxLjEwMyAzMDcuMTc4aDI1NnYyMDQuOGgtMjU2eiIvPgogICAgICA8cGF0aCBmaWxsPSIjZmVjYzAwIiBkPSJNLTEyMS4xMDMgMjA0Ljk4NGgyNTZ2MTAyLjRoLTI1NnoiLz4KICAgICAgPHBhdGggZmlsbD0iI2ZlY2MwMCIgZD0iTTEzMy44NDMuMDFoMTAyLjR2NTExLjk5N2gtMTAyLjR6Ii8+CiAgICAgIDxwYXRoIGZpbGw9IiNmZWNjMDAiIGQ9Ik0yMzIuOTk1IDIwNS4wMTNoNDYwLjc5OHYxMDIuNEgyMzIuOTk1eiIvPgogICAgICA8cGF0aCBmaWxsPSIjMDA2YWE3IiBkPSJNMjM2LjE1NSAzMDcuMjA4aDQ2MC43OTd2MjA0Ljc5OUgyMzYuMTU1ek0yMzYuMTU1LjMwMmg0NjAuNzk3VjIwNS4xSDIzNi4xNTV6Ii8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K);
  }

  &__no {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgwIiB3aWR0aD0iNjQwIiB2aWV3Qm94PSIwIDAgNjQwIDQ4MCI+CiAgPHBhdGggZmlsbD0iI2VmMmIyZCIgZD0iTTAgMGg2NDB2NDgwSDB6Ii8+CiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE4MCAwaDEyMHY0ODBIMTgweiIvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0wIDE4MGg2NDB2MTIwSDB6Ii8+CiAgPHBhdGggZmlsbD0iIzAwMjg2OCIgZD0iTTIxMCAwaDYwdjQ4MGgtNjB6Ii8+CiAgPHBhdGggZmlsbD0iIzAwMjg2OCIgZD0iTTAgMjEwaDY0MHY2MEgweiIvPgo8L3N2Zz4K);
  }

  &__fi {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgwIiB3aWR0aD0iNjQwIiB2aWV3Qm94PSIwIDAgNjQwIDQ4MCI+CiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGg2NDB2NDgwSDB6Ii8+CiAgPHBhdGggZmlsbD0iIzAwMzU4MCIgZD0iTTAgMTc0LjU0NWg2NDB2MTMwLjkwOUgweiIvPgogIDxwYXRoIGZpbGw9IiMwMDM1ODAiIGQ9Ik0xNzUuNDU1IDBoMTMwLjkwOXY0ODBIMTc1LjQ1NXoiLz4KPC9zdmc+Cg==);
  }
}
