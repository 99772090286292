@import '../../shared/_variables';
@import '../../shared/_icons';

.webcams {
  padding: $s-margin;
  text-align: center;
  max-width: $s-content;
  margin: 0 auto;

  @media screen and (min-width: $b-desktop) {
    padding: $s-margin 0;
  }

  &__htmlWrapper {
    width: 100%;

    @media screen and (min-width: $b-desktop) {
      width: 33%;
      float: left;
      padding: 0 $s-margin 0 0;
    }

    & > * {
      max-width: 100%;
    }
  }

  &__imageWrapper {
    width: 100%; 
    margin-bottom: $s-margin;
    outline: 0;

    @media screen and (min-width: $b-desktop) {
      width: 33%;
      float: left;
    }
  }

  &__image {
    // border-radius: 20px;
    width: 100%;
  }

  &__imageButton {
    border: none;
    background: transparent;
    position: relative;
    outline: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
    display: inline-block;

    @media screen and (min-width: $b-desktop) {
      margin: 0 $s-margin 0 0;
    }
  }

  &__imageTitle {
    position: absolute;
    bottom: $s-margin;
    left: $s-margin;
    font-size: $f-normal;
    text-shadow: 0 1px 5px rgba(0,0,0,.8);
    color: $c-white;

    @media screen and (min-width: $b-desktop) {
      font-size: $f-large;
    }
  }

  &__modal {
    margin: 0 auto;
    width: 100%;
    max-width: $s-content;
    height: 100%;
    background-color: $c-white;
    outline: 0;

    @media screen and (min-width: $b-desktop) {
      height: 700px;
      top: 50%;
      transform: translate(0, -50%);
      position: relative;
    }
  }

  &__modalOverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.15);
    z-index: 300;
  }

  &__modalHeader {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      background: transparent;
      border: 0;
      font-size: 1.5rem;
      min-width: 40px;
      min-height: 40px;
      margin-right: 0.5rem;
      cursor: pointer;
      position: relative;

      &:before, &:after {
        content: '';
        width: 2px;
        height: 14px;
        display: block;
        background-color: $c-black;

        position: absolute;
        left: 20px;
        top: 12px;
      }

      &:before {
        transform: rotate(45deg);        
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  &__iframe {
    width: 100%;
    height: calc( 100% - 50px );
    border: 0;
  }

  &__link {
    text-decoration: none;
    color: $c-pacific;
    transition: opacity 0.25s;

    &:hover {
      opacity: 0.5;
    }

    &:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 16px;
      width: 16px;
      margin-left: 3px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: icon('caret', $c-pacific);
    }
  }
}
