@import '../../../shared/_variables';
@import '../../../shared/_icons';

.trip__flight {
  h2 {
    margin: 0 0 10px;
    font-weight: normal;
    color: $c-congress;
  }

  @media screen and (min-width: $b-desktop) {
    width: 50%;
    border-right: 1px solid $c-gallery;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.trip__flight-row {
  padding: 0 30px;

  @media screen and (min-width: $b-desktop) {
    display: flex;
  }
}

.trip__flight-information {
  padding: 20px 0;

  @media screen and (min-width: $b-desktop) {
    padding: 10px 20px;
  }
}

.trip__flight-header {
  padding-left: 25px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 16px;
    background-position: top 3px center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: icon('departure', $c-black);
  }

  &.is-departure:before {
  }

  &.is-arrival:before {
    transform: scaleX(-1);
  }
}

.trip__flight-date {
  font-weight: bold;
}

.trip__flight-flight {
  color: $c-grey;
  font-weight: bold;
  text-transform: uppercase;
  font-size: $f-small;
}

.trip__flight-timeline {
  border-left: 1px dashed $c-dusty;
  padding: 0 20px;
  margin: 20px 4px 5px;
  position: relative;
}

$l-circle: 11px;

.trip__flight-departure,
.trip__flight-arrival {
  &:before {
    content: '';
    height: $l-circle;
    width: $l-circle;
    position: absolute;
    background-color: $c-white;
    border: 2px solid $c-dusty;
    border-radius: 50%;
    left: -#{ceil(calc($l-circle/2))};
  }
}

.trip__flight-departure {
  &:before {
    top: 0;
  }
}

.trip__flight-arrival {
  &:before {
    bottom: 0;
  }
}

.trip__flight-airport {
  &.is-departure {
    transform: translate(0px, -4px);
  }

  &.is-arrival {
    transform: translate(0px, 4px);
  }
}

.trip__flight-duration {
  font-size: $f-small;
  color: $c-grey;
  margin-bottom: 30px;
}

.trip__flight-luggage {
  padding: 10px;
  background-color: $c-alabaster;
  margin: 0 0 20px;

  @media screen and (min-width: $b-desktop) {
    margin: 20px 0 0;
    padding: 10px 20px;
  }
}

.trip__flight-luggage-help {
  font-size: $f-small;
  color: $c-grey;
}

.trip__flight-add {
  color: $c-pacific;
  font-weight: bold;
}

.trip__flight-bag {
  margin: 15px;
}

.trip__flight-ski {
  margin: 15px;
}

.trip__flight-summary {
  font-weight: bold;
}
