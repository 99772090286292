@import '../../../shared/_variables';

.trip__bottomNotice {
    background-color: $c-lemon;
    color: $c-black;
    text-align: center;
    padding: 5px;
    width: 100%;
    margin-bottom: $s-margin;

    span {
        color: $c-limeade;
        font-weight: bold;
    }
}