@import '../../../shared/_variables';

.location-s__contact {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  margin-top: 10px;
  margin-bottom: -20px;
}

.location-s__contact-image {
  height: 100px;
  width: 110px;
  padding: 10px 15px;
}

.location-s__contact-file {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.location-s__contact-content {
  flex: 1;
  padding-left: 15px;
  letter-spacing: 1px;

  @media screen and (min-width: $b-desktop) {
    flex: unset;
  }
}

.location-s__contact-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.location-s__contact-link {
  color: $c-pacific;
  font-weight: bold;
}
