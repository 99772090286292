@import '../../../shared/_variables';

.TripAfterInfo {
    width: 100%;
    position: relative;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    margin-top: $s-margin;
    margin-bottom: -20px;

    @media screen and (min-width: $b-desktop) {
        margin-top: 0;
        margin-bottom: -50px;
    }

    &__image img {
        width: 100%;
    }

    &__content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: $s-margin;
        color: $c-white;

        @media screen and (min-width: $b-desktop) {
            padding: 50px;
        }

        &--vertical-top {
            justify-content: flex-start;
        }

        &--vertical-center {
            justify-content: center;
        }

        &--vertical-bottom {
            justify-content: flex-end;
        }

        &--horizontal-left {
            align-items: flex-start;
        }

        &--horizontal-center {
            align-items: center;
        }

        &--horizontal-right {
            align-items: flex-end;
        }
    }

    &__title {
        text-shadow: 0 1px 5px rgba(0,0,0,.8);
        margin: 0;
        order: 1;
    }

    &__text {
        text-shadow: 0 0.5px 2px rgba(0,0,0,.8);
        order: 2;
    }

    &__cta {
        border: 0;
        outline: 0;
        cursor: pointer;
        color: $c-white;
        background-color: $c-limeade;
        order: 3;
        padding: 10px 15px;
        height: initial;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &--thunderbird {
            color: $c-white;
            background-color: $c-thunderbird;
        }

        &--turbo {
            color: $c-black;
            background-color: $c-turbo;
        }

        &--limeade {
            color: $c-white;
            background-color: $c-limeade;
        }

        &--pos-abovecontent {
            order: 0;
        }
    }
}