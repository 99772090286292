@import '../../../shared/_variables';
@import '../../../shared/_icons';

.location-ll__title {
  text-align: center;

  .location-ll__back {
    color: $c-lochmara;;

    &:before {
      background-image: icon('caret', $c-lochmara);
    }
  }
}

// TODO: Merge with tripHero? But with centered text? And here with margin-bottom
.location-ll__hero {
  background-position: center;
  background-size: cover;
  position: relative;
  color: $c-white;
  padding: 30px 0;
  text-align: center;
  margin-bottom: -30px; // Put linklist connected to the image

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  > div {
    position: relative;
    text-shadow: 0 0.5px 2px rgba(0,0,0,.8);
  }

  @media screen and (min-width: $b-desktop) {
    order: -1;
    padding: 50px 0;
    margin-bottom: 0;
  }

  .location-ll__back {
    color: $c-lochmara;

    &:before {
      background-image: icon('caret', $c-lochmara);
    }
  }
}

.location-ll__back {
  align-items: center;
  justify-content: center;
  color: $c-lochmara;
  display: flex;
  font-weight: bold;
  text-decoration: none;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.5;
  }

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 16px;
    width: 16px;
    margin-right: 5px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(180deg);
  }
}
