@import '../../shared/_variables';

.error-content {
  flex: 1;
  background-color: $c-bg;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $c-text;
}

.error-logo {
  height: 20vmin;
  width: 40vmin;
}
