@import '../../shared/_variables';

.login-content {
  flex: 1;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $c-text;
}

.login-logo {
  height: 20vmin;
  width: 40vmin;
}

// FORM
.login__form {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
}

.login__label {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.login__input {
  font-size: 16px;
  background: $c-bg;
  padding: 5px;
  margin-top: 3px;
  border: 1px solid $c-gallery;
}

.login__i-submit {
  margin: 20px 0 0;
  background: $c-limeade;
  color: $c-white;
  padding: 10px;
  border: 0;
  font-weight: bold;
}

.login__error {
  color: $c-thunderbird;
}

// LIST OF LOCATIONS
.login-locations {
  margin: 30px;
  text-align: center;

  a {
    margin: 10px;
    color: $c-link;
    display: block;

    &:hover {
      text-decoration: none;
    }
  }
}

.login__separator{
  position: relative;
  margin-top: 10px;
  border-top: 1px solid $c-alto;
  text-align: center;

  span{
    position: relative;
    top: -12px;
    background-color: #ffffff;
    padding: 0 10px;
  }
}