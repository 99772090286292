@import '../../shared/_variables';
@import '../../shared/_icons';

.mainnav-container {
  border: 1px solid $c-gallery;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 $s-margin;
  font-size: $f-small;
  z-index: 10;
  background-color: $c-white;
  transform: translate3d(0,0,0);

  @media screen and (min-width: $b-desktop) {
    position: relative;
    border: 0;
    flex-shrink: 0;
    width: 100%;
  }
}

.mainnav-content {
  max-width: $s-content;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: auto;
  padding: 10px 0;
  margin-bottom: calc( 1.5 * env(safe-area-inset-bottom) );

  @media screen and (min-width: $b-desktop) {
    justify-content: center;
    height: auto;
  }
}

.mainnav-link {
  align-items: center;
  color: $c-grey;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  transition: opacity 0.25s;
  text-decoration: none;
  letter-spacing: 1px;
  height: $s-mainnav;
  justify-content: center;
  width: min-content;
  text-align: center;
  font-size: 0.7em;

  &:before {
    content: '';
    display: block;
    height: 20px;
    width: 20px;
    margin: 0 auto 1px;
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.is-trip:before {
    background-image: icon('trip', $c-grey);
  }

  &.is-activity:before {
    background-image: icon('activity', $c-grey);
  }

  &.is-skiing:before {
    background-image: icon('skiing', $c-grey);
  }

  &.is-hiking:before {
    background-image: icon('hiking', $c-grey);
  }

  &.is-location:before {
    background-image: icon('location', $c-grey);
  }

  &.is-active {
    font-weight: bold;
    color: $c-black;

    @media screen and (min-width: $b-desktop) {
      color: $c-limeade;
    }

    &.is-trip:before {
      background-image: icon('trip', $c-limeade);
    }

    &.is-activity:before {
      background-image: icon('activity', $c-limeade);
    }

    &.is-skiing:before {
      background-image: icon('skiing', $c-limeade);
    }

    &.is-hiking:before {
      background-image: icon('hiking', $c-limeade);
    }

    &.is-location:before {
      background-image: icon('location', $c-limeade);
    }
  }

  @media screen and (min-width: $b-desktop) {
    font-size: $f-normal;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    border-right: 1px solid $c-mercury;
    color: $c-black;
    height: 25px;
    flex-grow: 0;
    width: auto;

    &:first-child {
      margin-left: 20px;
    }

    &:last-child {
      border-right: none;
    }

    &:before {
      content: none;
    }

    &:hover {
      opacity: 0.5;
    }
  }
}
