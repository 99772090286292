@import '../../shared/_variables';
@import '../../shared/_icons';

.linklist__list {
  margin: 30px 0 0;

  @media screen and (min-width: $b-desktop) {
    display: flex;
    flex-wrap: wrap;
  }
}

.linklist__wrapper {
  margin-bottom: -1px;

  @media screen and (min-width: $b-desktop) {
    padding: 10px;
    flex: 0 1 33.33%;
  }
}

.linklist__link {
  display: flex;
  justify-content: space-between;
  color: $c-black;
  font-weight: bold;
  text-decoration: none;
  padding: 10px 25px 10px 15px;
  border: 1px solid $c-gallery;
  position: relative;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.5;
  }

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    right: 6px;
    top: 12px;
    height: 16px;
    width: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: icon('caret', $c-black);
  }
}

.linklist__link-external {
    flex-shrink: 0;
    font-weight: normal;
    font-size: $f-medium;
    color: $c-grey;
    padding: 0px 10px;
    border: 1px solid $c-grey;
    border-radius: 3px;
}
