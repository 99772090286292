@import '../../shared/_variables';
@import '../../shared/_icons';

.header-container {
  padding: 0 $s-margin;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background-color: $c-white;
}

.header-content {
  max-width: $s-content;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $s-header;
}

.header-main {
  display: flex;
  align-items: center;
}

.header-logo {
  max-width: 80px;
  max-height: 16px;

  @media (min-width: 700px) {
    max-width: 140px;
    max-height: 20px;
  }
}

.header-title {
  font-size: $f-large;
  font-weight: bold;
  text-align: center;

  @media screen and (min-width: $b-desktop) {
    display: none;
  }
}

.header-logo,
.header-notifications {
  width: 80px;

  @media (min-width: 700px) {
    width: 140px;
  }
}

.header-notifications {
  text-align: right;
  // background-image: url('../../shared/icons/notification.svg');
  background-repeat: no-repeat;
  background-position: bottom right 15px;
  background-size: 15px;
  position: relative;
}

.header-notification-bell {
  background-image: icon('bell', $c-mine);
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;

  &--active {
    background-image: icon('bell', $c-pacific);
  }
}

.header-amount {
  text-decoration: none;
  border-radius: 50%;
  background-color: $c-pacific;
  height: 20px;
  width: 20px;
  color: $c-white;
  font-size: $f-small;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  position: absolute;
  top: -8px;
  right: -8px;
}
