@import '../../../shared/_variables';

.trip__destination {
  margin: 10px 0;

  h2 {
    margin: 0 0 10px;
    font-weight: normal;
    color: $c-congress;
  }

  @media screen and (min-width: $b-desktop) {
    margin: 0 0 0 50px;
    width: 50%;
    flex-grow: 0;
  }
}

.trip__destination-content {
  margin: 0 20px;

  @media screen and (min-width: $b-desktop) {
    margin: 0;
  }
}

.trip__destination-row {
  display: flex;
  margin-bottom: 7px;
}

.trip__destination-label {
  //flex: 2;
}

.trip__destination-spacer {
  flex: 2;
  border-bottom: 1px dashed $c-grey;
  margin: 0 5px;
  height: 1rem;
}

.trip__destination-value {
  //font-weight: bold;
  max-width: 70%;
  //flex: 3;
}

.trip__destination-link {
  cursor: pointer;

  &-text {
    color: $c-pacific;
    text-decoration: underline;
  }
}
