@import '../../../shared/_variables';
@import '../../../shared/_icons';

.trip__faq {
  padding: 50px 0;
  text-align: center;
}

.trip__faq-link {
  text-decoration: none;
  color: $c-pacific;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.5;
  }

  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 16px;
    width: 16px;
    margin-left: 3px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: icon('caret', $c-pacific);
  }
}
