@import '../../../shared/_variables';
@import '../../../shared/_icons';

// TODO: Merge with tripHero? But with bottom text?
.activity-i__hero {
  background-position: center;
  background-size: cover;
  position: relative;
  color: $c-white;
  padding: 30px $s-margin;
  text-align: left;
  min-height: 250px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  > div {
    position: relative;
  }

  @media screen and (min-width: $b-desktop) {
    padding: 50px 0;
  }
}

.activity-i__hero-content {
  margin: 0 auto;
  max-width: 700px;
  text-shadow: 0 0.5px 2px rgba(0,0,0,.8);
}

.activity-i__hero-title {
  margin: 0;
  text-shadow: 0 1px 5px rgba(0,0,0,.8);
}

// Back link

.activity-i__back {
  margin-top: 20px;
}

.activity-i__back-link {
  font-weight: bold;
  color: $c-black;
  text-decoration: none;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.5;
  }

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 16px;
    width: 16px;
    margin-right: 5px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: icon('caret', $c-black);
    transform: rotate(180deg);
  }
}

// Meta
.activity-i__meta {
  padding: 20px 0 0;
  font-size: $f-small;
  color: $c-grey;
}

.activity-i__meta-line {
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
}

.activity-i__meta-label {
  flex: 0 0 75px;
  font-weight: bold;
  text-transform: uppercase;
}

.activity-i__meta-value {
  flex: 1;
  display: flex;
}

.activity-i__meta-info {
  padding-left: 4px;
  color: $c-pacific;
}

// CONTENT
.activity-i__content {
  max-width: 600px;
}

// BOOKING INFORMATION
.activity-i__book {
  margin-top: 30px;
  text-align: center;
}

.activity-i__price {
  font-size: $f-small;
  color: $c-grey;
  font-weight: bold;
  text-transform: uppercase;
}

.activity-i__deadline {
  font-size: $f-small;
  color: $c-monza;
}

.activity-i__canceled {
  color: $c-monza;
}

.activity-i__cta {
  background-color: $c-limeade;
  color: $c-white;
  font-weight: bold;
  display: block;
  padding: 15px;
  text-decoration: none;
  transition: background-color 0.25s;
  margin: 10px 0;

  @media screen and (min-width: $b-desktop) {
    &:hover {
      background-color: $c-mine;
    }
  }

  &--disabled {
    background-color: $c-mercury;
    color: $c-grey;

    @media screen and (min-width: $b-desktop) {
      &:hover {
        background-color: $c-mercury;
      }
    }
  }

}

.activity-i__description {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.activity-i__usps {
  padding: 0 0 0 30px;
  margin: 1rem 0 -0.6rem 0;
  list-style: none;
}

.activity-i__usp {
  margin: 7px 0;
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: -27px;
    height: 16px;
    width: 16px;
    margin-right: 4px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: icon('checkmark', $c-limeade);
  }
}