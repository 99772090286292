@import '../../shared/_variables';
@import '../../shared/_icons';

.notification {
  position: fixed;
  top: 52px;
  left: 2px;
  right: 2px;
  background-color: $c-solitude;
  padding: 10px 0 10px;
  text-align: center;
  box-shadow: 1px 2px 5px $c-black;
  z-index: 2; // TODO: Remove use of z-index
}

.notification__content {
  display: flex;
  padding-right: 40px;
  position: relative;
}

.notifiction__close {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-image: icon('close-circle', $c-pacific);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media screen and (min-width: $b-desktop) {
    right: 0;
  }
}
