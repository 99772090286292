@import '../../../shared/_variables';

.activity__schedule-day {
  background-color: $c-gallery;
  color: $c-grey;
  font-size: $f-small;
  text-transform: uppercase;
  padding: 10px 0;
  font-weight: bold;

  .app-content {
    max-width: 600px;
  }

  @media screen and (min-width: $b-desktop) {
    text-align: center;
  }
}
