@import '../../../shared/_variables';

.trip__travellers {
  margin: 10px 0;

  h2 {
    margin: 0 0 10px;
    font-weight: normal;
    color: $c-congress;
  }

  @media screen and (min-width: $b-desktop) {
    flex: 1;
    padding: 0;
    margin: 0;
  }
}

.trip__travellers-person {
  margin: 30px 20px;
  display: block;

  &:first-of-type {
    margin-top: 0;
  }

  @media screen and (min-width: $b-desktop) {
    display: none;
  }
}

.trip__travellers-table {
  width: calc(100% - #{$s-margin} * 2);
  border-collapse: collapse;
  margin: 0 $s-margin;
  display: none;

  @media screen and (min-width: $b-desktop) {
    display: table;
  }
}

.trip__travellers-row {  
  td {
    border-bottom: 1px solid $c-gallery;
    padding: $s-margin;
  }
}

.trip__travellers-label {
  flex: 2;
}

.trip__travellers-value {
  font-weight: bold;
  flex: 3;
}
