@import '../../../shared/_variables';
@import '../../../shared/_icons';

.trip__includes {
  margin: 30px 0;

  h2 {
    margin: 0;
    font-weight: normal;
    color: $c-congress;
  }

  @media screen and (min-width: $b-desktop) {
    flex: 1;
    padding: 0;
    margin: 0;
  }
}

.trip__includes-header {
  display: flex;
  align-items: center;
  margin: 0 0 10px;

  h2 {
    flex: 1;
  }
}

.trip__includes-addons {
  color: $c-grey;
  font-weight: bold;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.5;
  }
}

.trip__includes-row {
  display: flex;
  margin: 7px 0;
  border: 1px solid $c-gallery;
  padding: 10px;

  @media screen and (min-width: $b-desktop) {
    display: none;
  }
}

.trip__includes-table {
  width: calc(100% - #{$s-margin} * 2);
  border-collapse: collapse;
  margin: 0 $s-margin;
  display: none;

  @media screen and (min-width: $b-desktop) {
    display: table;
  }
}

.trip__includes-table-row {  
  td {
    border-bottom: 1px solid $c-gallery;
    padding: $s-margin;
  }
}

.trip__includes-title {
  flex: 1;
}

.trip__includes-name {
  letter-spacing: 1px;
}

.trip__includes-edit {
  color: $c-pacific;
  font-size: $f-small;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.5;
  }

  @media screen and (min-width: $b-desktop) {
    font-size: $f-normal;
    float: right;
  }
}

.trip__includes-prefix {
  font-weight: bold;
  text-align: right;
}

.trip__includes-price {
  font-size: 14px;
  color: $c-dusty;
}

.trip__includes-cta {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $s-margin;
}