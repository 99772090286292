@import '../../../shared/_variables';
@import '../../../shared/_icons';

.trip__addonGroup {

  &Title {
    background-color: $c-gallery;
    border-radius: $s-radius;
    padding: 10px $s-margin;
    font-weight: bold;
    margin: 30px -#{$s-margin} $s-margin -#{$s-margin}
  }

  &:last-of-type {
    margin-bottom: 50px;
  }
}

.trip__addons {

  &--slim {

    @media screen and (max-width: $b-desktop) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $s-margin;
    }
  }
  @media screen and (min-width: $b-desktop) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 70px;
  }
}

$addonSpacing: 20px;

.trip__addon {
  padding: 15px 0;

  @media screen and (min-width: $b-desktop) {
    padding: 10px;
    flex: 0 1 33%;
  }

  &--slim {
    padding: 0;

    @media screen and (min-width: $b-desktop) {
      padding: 10px;
      flex: 0 1 33%;
    }
  }
}

.trip__addon-content {
  @media screen and (min-width: $b-desktop) {
    display: flex;
    flex-direction: column;
  }
  height: 100%;
  position: relative;
  border: 1px solid $c-gallery;
}

.trip__addon-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  flex: 1;
  padding-top: $addonSpacing;
  padding-left: $addonSpacing;

  .trip__addon--slim & {
    padding-top: $s-margin-small;
    padding-left: $s-margin-small;

    @media screen and (max-width: $b-desktop) {
      height: 100%;
    }
  }
}

.trip__addon-deadline {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px 5px;
  background: $c-thunderbird;
  color: $c-white;
  font-size: $f-small;
  font-weight: bold;
  text-transform: uppercase;
}

.trip__addon-title {
  display: flex;
  justify-content: space-between;
  padding-right: $addonSpacing;
  word-break: break-word;
  hyphens: auto;

  .trip__addon--slim & {
    padding-right: $s-margin-small;
  }
}

.trip__addon-name {
  font-weight: bold;
  flex: 1;
}

.trip__addon-image {
  background-size: cover;
  background-position: center;
  height: 0px;
  width: 100%;
  padding-bottom: 40%;
}

.trip__addon-description {
  padding: 0 0 10px 30px;
  margin: 0 $addonSpacing 0 0;
  font-size: $f-small;
  color: $c-grey;
  list-style: none;
}

.trip__addon-usp {
  margin: 7px 0;
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: -27px;
    height: 16px;
    width: 16px;
    margin-right: 4px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: icon('checkmark', $c-limeade);
  }
}

.trip__addon-cta {
  margin-top: auto;
  text-align: right;
}

.trip__addon-link {
  background-color: $c-limeade;
  padding: 5px 25px;
  display: inline-block;
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: $f-medium;
  transition: background-color 0.25s;

  .trip__addon-cta--slim & {
    background-color: $c-dusty;
  }

  &:hover {
    background-color: $c-mine;
  }

  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 16px;
    width: 16px;
    margin-left: 4px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: icon('arrow', $c-white);
  }
}

.trip__addon-duration {
  margin-left: 5px;
  margin-top: 3px;
  font-size: $f-medium;
}
