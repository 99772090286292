@import '../../../shared/_variables';
@import '../../../shared/_icons';

.activity__schedule-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 20px ($s-margin + 30px) 20px $s-margin;
  border-bottom: 1px solid $c-gallery;
  max-width: 500px;
  text-decoration: none;
  color: $c-black;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.5;
  }

  &:last-child {
    border: 0;
  }

  &:after {
    content: '';
    position: absolute;
    right: 10px;
    height: 16px;
    width: 16px;
    margin-right: 5px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: icon('caret', $c-black);
  }
}

.activity__schedule-item-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: $c-black;
  transition: opacity 0.25s;
}

.activity__schedule-time {
  font-size: $f-small;
  color: $c-grey;
}

.activity__schedule-booking {
  flex-shrink: 0;
  font-size: $f-small;
  color: $c-grey;
  padding: 3px 5px;
  border: 1px solid $c-limeade;
  border-radius: 3px;

  &--canceled {
    border-color: $c-monza;
  }
}

.activity__usps {
  padding: 0 0 0 30px;
  margin: 0;
  font-size: $f-small;
  color: $c-grey;
  list-style: none;
}

.activity__usp {
  margin: 7px 0;
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: -27px;
    height: 16px;
    width: 16px;
    margin-right: 4px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: icon('checkmark', $c-limeade);
  }
}
