@import url(https://fonts.googleapis.com/css?family=PT+Sans:400,400italic,700,700italic&display=swap);
*,
*:before,
*:after {
  box-sizing: border-box;
}

body,
html {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.app-content {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 15px;
}
.app-content.no-padding {
  padding: 0;
  margin-bottom: 30px;
}
@media screen and (min-width: 1200px) {
  .app-content {
    padding: 0;
  }
}

.btn {
  color: #ffffff;
  background: #47a404;
  padding: 10px 30px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: background-color 0.25s;
  transition: background-color 0.25s;
}
@media screen and (min-width: 1200px) {
  .btn {
    display: inline-flex;
    padding: 10px 50px;
    border-radius: 3px;
  }
  .btn:hover {
    background-color: #3d3d3d;
  }
}
.btn--alert {
  background-color: #d0021b;
}
@media screen and (min-width: 1200px) {
  .btn--alert:hover {
    background-color: #3d3d3d;
  }
}
.btn--transparent {
  background-color: transparent;
  color: #009acd;
  border: 1px solid #3d3d3d;
}
@media screen and (min-width: 1200px) {
  .btn--transparent:hover {
    background-color: #3d3d3d;
  }
}

h1 {
  font-size: 2rem;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #1e1e1c;
  border-color: #1e1e1c transparent #1e1e1c transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.login-content {
  flex: 1 1;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #1e1e1c;
}

.login-logo {
  height: 20vmin;
  width: 40vmin;
}

.login__form {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
}

.login__label {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.login__input {
  font-size: 16px;
  background: #ffffff;
  padding: 5px;
  margin-top: 3px;
  border: 1px solid #ebebeb;
}

.login__i-submit {
  margin: 20px 0 0;
  background: #47a404;
  color: #ffffff;
  padding: 10px;
  border: 0;
  font-weight: bold;
}

.login__error {
  color: #cf1011;
}

.login-locations {
  margin: 30px;
  text-align: center;
}
.login-locations a {
  margin: 10px;
  color: #009acd;
  display: block;
}
.login-locations a:hover {
  text-decoration: none;
}

.login__separator {
  position: relative;
  margin-top: 10px;
  border-top: 1px solid #d4d4d4;
  text-align: center;
}
.login__separator span {
  position: relative;
  top: -12px;
  background-color: #ffffff;
  padding: 0 10px;
}
.framework-container {
  padding: 50px 0 50px;
  margin-bottom: calc(1.5 * env(safe-area-inset-bottom));
  min-height: 100vh;
}
.header-container {
  padding: 0 15px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background-color: #ffffff;
}

.header-content {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.header-main {
  display: flex;
  align-items: center;
}

.header-logo {
  max-width: 80px;
  max-height: 16px;
}
@media (min-width: 700px) {
  .header-logo {
    max-width: 140px;
    max-height: 20px;
  }
}

.header-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
@media screen and (min-width: 1200px) {
  .header-title {
    display: none;
  }
}

.header-logo,
.header-notifications {
  width: 80px;
}
@media (min-width: 700px) {
  .header-logo,
.header-notifications {
    width: 140px;
  }
}

.header-notifications {
  text-align: right;
  background-repeat: no-repeat;
  background-position: bottom right 15px;
  background-size: 15px;
  position: relative;
}

.header-notification-bell {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='bell' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' class='svg-inline--fa fa-bell fa-w-14 fa-7x'%3E%3Cpath fill='%233d3d3d' d='M439.39 362.29c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71zM67.53 368c21.22-27.97 44.42-74.33 44.53-159.42 0-.2-.06-.38-.06-.58 0-61.86 50.14-112 112-112s112 50.14 112 112c0 .2-.06.38-.06.58.11 85.1 23.31 131.46 44.53 159.42H67.53zM224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64z' class=''%3E%3C/path%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
}
.header-notification-bell--active {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='bell' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' class='svg-inline--fa fa-bell fa-w-14 fa-7x'%3E%3Cpath fill='%23009acd' d='M439.39 362.29c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71zM67.53 368c21.22-27.97 44.42-74.33 44.53-159.42 0-.2-.06-.38-.06-.58 0-61.86 50.14-112 112-112s112 50.14 112 112c0 .2-.06.38-.06.58.11 85.1 23.31 131.46 44.53 159.42H67.53zM224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64z' class=''%3E%3C/path%3E%3C/svg%3E");
}

.header-amount {
  text-decoration: none;
  border-radius: 50%;
  background-color: #009acd;
  height: 20px;
  width: 20px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  position: absolute;
  top: -8px;
  right: -8px;
}
.mainnav-container {
  border: 1px solid #ebebeb;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 15px;
  font-size: 12px;
  z-index: 10;
  background-color: #ffffff;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@media screen and (min-width: 1200px) {
  .mainnav-container {
    position: relative;
    border: 0;
    flex-shrink: 0;
    width: 100%;
  }
}

.mainnav-content {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: auto;
  padding: 10px 0;
  margin-bottom: calc(1.5 * env(safe-area-inset-bottom));
}
@media screen and (min-width: 1200px) {
  .mainnav-content {
    justify-content: center;
    height: auto;
  }
}

.mainnav-link {
  align-items: center;
  color: #858585;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  text-decoration: none;
  letter-spacing: 1px;
  height: 50px;
  justify-content: center;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  text-align: center;
  font-size: 0.7em;
}
.mainnav-link:before {
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  margin: 0 auto 1px;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}
.mainnav-link.is-trip:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%23858585'%3E%3Cpath d='M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-352 96c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H86.4C74 384 64 375.4 64 364.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5c12.3 5.1 25.7 8 39.8 8s27.6-2.9 39.8-8h5c37.1 0 67.2 25.8 67.2 57.6v19.2zM512 312c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.mainnav-link.is-activity:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%23858585'%3E%3Cpath d='M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm64-192c0-8.8 7.2-16 16-16h96c8.8 0 16 7.2 16 16v96c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-96zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.mainnav-link.is-skiing:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%23858585'%3E%3Cpath d='M432 96c26.5 0 48-21.5 48-48S458.5 0 432 0s-48 21.5-48 48 21.5 48 48 48zm73 356.1c-9.4-9.4-24.6-9.4-33.9 0-12.1 12.1-30.5 15.4-45.1 8.7l-135.8-70.2 49.2-73.8c12.7-19 10.2-44.5-6-60.6L293 215.7l-107-53.1c-2.9 19.9 3.4 40 17.7 54.4l75.1 75.2-45.9 68.8L35 258.7c-11.7-6-26.2-1.5-32.3 10.3-6.1 11.8-1.5 26.3 10.3 32.3l391.9 202.5c11.9 5.5 24.5 8.1 37.1 8.1 23.2 0 46-9 63-26 9.3-9.3 9.3-24.5 0-33.8zM120 91.6l-11.5 22.5c14.4 7.3 31.2 4.9 42.8-4.8l47.2 23.4c-.1.1-.1.2-.2.3l114.5 56.8 32.4-13 6.4 19.1c4 12.1 12.6 22 24 27.7l58.1 29c15.9 7.9 35 1.5 42.9-14.3 7.9-15.8 1.5-35-14.3-42.9l-52.1-26.1-17.1-51.2c-8.1-24.2-40.9-56.6-84.5-39.2l-81.2 32.5-62.5-31c.3-14.5-7.2-28.6-20.9-35.6l-11.1 21.7h-.2l-34.4-7c-1.8-.4-3.7.2-5 1.7-1.9 2.2-1.7 5.5.5 7.4l26.2 23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.mainnav-link.is-hiking:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%23858585'%3E%3Cpath d='M80.95 472.23c-4.28 17.16 6.14 34.53 23.28 38.81 2.61.66 5.22.95 7.8.95 14.33 0 27.37-9.7 31.02-24.23l25.24-100.97-52.78-52.78-34.56 138.22zm14.89-196.12L137 117c2.19-8.42-3.14-16.95-11.92-19.06-43.88-10.52-88.35 15.07-99.32 57.17L.49 253.24c-2.19 8.42 3.14 16.95 11.92 19.06l63.56 15.25c8.79 2.1 17.68-3.02 19.87-11.44zM368 160h-16c-8.84 0-16 7.16-16 16v16h-34.75l-46.78-46.78C243.38 134.11 228.61 128 212.91 128c-27.02 0-50.47 18.3-57.03 44.52l-26.92 107.72a32.012 32.012 0 0 0 8.42 30.39L224 397.25V480c0 17.67 14.33 32 32 32s32-14.33 32-32v-82.75c0-17.09-6.66-33.16-18.75-45.25l-46.82-46.82c.15-.5.49-.89.62-1.41l19.89-79.57 22.43 22.43c6 6 14.14 9.38 22.62 9.38h48v240c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16V176c.01-8.84-7.15-16-15.99-16zM240 96c26.51 0 48-21.49 48-48S266.51 0 240 0s-48 21.49-48 48 21.49 48 48 48z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.mainnav-link.is-location:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%23858585'%3E%3Cpath d='M634.92 462.7l-288-448C341.03 5.54 330.89 0 320 0s-21.03 5.54-26.92 14.7l-288 448a32.001 32.001 0 0 0-1.17 32.64A32.004 32.004 0 0 0 32 512h576c11.71 0 22.48-6.39 28.09-16.67a31.983 31.983 0 0 0-1.17-32.63zM320 91.18L405.39 224H320l-64 64-38.06-38.06L320 91.18z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.mainnav-link.is-active {
  font-weight: bold;
  color: #1e1e1c;
}
@media screen and (min-width: 1200px) {
  .mainnav-link.is-active {
    color: #47a404;
  }
}
.mainnav-link.is-active.is-trip:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%2347a404'%3E%3Cpath d='M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-352 96c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H86.4C74 384 64 375.4 64 364.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5c12.3 5.1 25.7 8 39.8 8s27.6-2.9 39.8-8h5c37.1 0 67.2 25.8 67.2 57.6v19.2zM512 312c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.mainnav-link.is-active.is-activity:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%2347a404'%3E%3Cpath d='M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm64-192c0-8.8 7.2-16 16-16h96c8.8 0 16 7.2 16 16v96c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-96zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.mainnav-link.is-active.is-skiing:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%2347a404'%3E%3Cpath d='M432 96c26.5 0 48-21.5 48-48S458.5 0 432 0s-48 21.5-48 48 21.5 48 48 48zm73 356.1c-9.4-9.4-24.6-9.4-33.9 0-12.1 12.1-30.5 15.4-45.1 8.7l-135.8-70.2 49.2-73.8c12.7-19 10.2-44.5-6-60.6L293 215.7l-107-53.1c-2.9 19.9 3.4 40 17.7 54.4l75.1 75.2-45.9 68.8L35 258.7c-11.7-6-26.2-1.5-32.3 10.3-6.1 11.8-1.5 26.3 10.3 32.3l391.9 202.5c11.9 5.5 24.5 8.1 37.1 8.1 23.2 0 46-9 63-26 9.3-9.3 9.3-24.5 0-33.8zM120 91.6l-11.5 22.5c14.4 7.3 31.2 4.9 42.8-4.8l47.2 23.4c-.1.1-.1.2-.2.3l114.5 56.8 32.4-13 6.4 19.1c4 12.1 12.6 22 24 27.7l58.1 29c15.9 7.9 35 1.5 42.9-14.3 7.9-15.8 1.5-35-14.3-42.9l-52.1-26.1-17.1-51.2c-8.1-24.2-40.9-56.6-84.5-39.2l-81.2 32.5-62.5-31c.3-14.5-7.2-28.6-20.9-35.6l-11.1 21.7h-.2l-34.4-7c-1.8-.4-3.7.2-5 1.7-1.9 2.2-1.7 5.5.5 7.4l26.2 23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.mainnav-link.is-active.is-hiking:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%2347a404'%3E%3Cpath d='M80.95 472.23c-4.28 17.16 6.14 34.53 23.28 38.81 2.61.66 5.22.95 7.8.95 14.33 0 27.37-9.7 31.02-24.23l25.24-100.97-52.78-52.78-34.56 138.22zm14.89-196.12L137 117c2.19-8.42-3.14-16.95-11.92-19.06-43.88-10.52-88.35 15.07-99.32 57.17L.49 253.24c-2.19 8.42 3.14 16.95 11.92 19.06l63.56 15.25c8.79 2.1 17.68-3.02 19.87-11.44zM368 160h-16c-8.84 0-16 7.16-16 16v16h-34.75l-46.78-46.78C243.38 134.11 228.61 128 212.91 128c-27.02 0-50.47 18.3-57.03 44.52l-26.92 107.72a32.012 32.012 0 0 0 8.42 30.39L224 397.25V480c0 17.67 14.33 32 32 32s32-14.33 32-32v-82.75c0-17.09-6.66-33.16-18.75-45.25l-46.82-46.82c.15-.5.49-.89.62-1.41l19.89-79.57 22.43 22.43c6 6 14.14 9.38 22.62 9.38h48v240c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16V176c.01-8.84-7.15-16-15.99-16zM240 96c26.51 0 48-21.49 48-48S266.51 0 240 0s-48 21.49-48 48 21.49 48 48 48z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.mainnav-link.is-active.is-location:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%2347a404'%3E%3Cpath d='M634.92 462.7l-288-448C341.03 5.54 330.89 0 320 0s-21.03 5.54-26.92 14.7l-288 448a32.001 32.001 0 0 0-1.17 32.64A32.004 32.004 0 0 0 32 512h576c11.71 0 22.48-6.39 28.09-16.67a31.983 31.983 0 0 0-1.17-32.63zM320 91.18L405.39 224H320l-64 64-38.06-38.06L320 91.18z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
@media screen and (min-width: 1200px) {
  .mainnav-link {
    font-size: 16px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    border-right: 1px solid #e4e4e4;
    color: #1e1e1c;
    height: 25px;
    flex-grow: 0;
    width: auto;
  }
  .mainnav-link:first-child {
    margin-left: 20px;
  }
  .mainnav-link:last-child {
    border-right: none;
  }
  .mainnav-link:before {
    content: none;
  }
  .mainnav-link:hover {
    opacity: 0.5;
  }
}
.notification {
  position: fixed;
  top: 52px;
  left: 2px;
  right: 2px;
  background-color: #e9f7ff;
  padding: 10px 0 10px;
  text-align: center;
  box-shadow: 1px 2px 5px #1e1e1c;
  z-index: 2;
}

.notification__content {
  display: flex;
  padding-right: 40px;
  position: relative;
}

.notifiction__close {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%23009acd'%3E%3Cpath d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (min-width: 1200px) {
  .notifiction__close {
    right: 0;
  }
}
.note-message__link {
  font-weight: bold;
  color: #009acd;
}
.error-content {
  flex: 1 1;
  background-color: #ffffff;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #1e1e1c;
}

.error-logo {
  height: 20vmin;
  width: 40vmin;
}
.trip-content {
  display: flex;
  flex-direction: column;
}
.trip__row {
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .trip__row {
    display: flex;
    margin: 30px auto;
  }
}
.trip__row.align-top {
  align-items: flex-start;
}

.trip__information {
  background-color: #e9f7ff;
  padding: 20px;
}

.trip__information-column {
  text-align: center;
  margin: 30px 0;
}
@media screen and (min-width: 1200px) {
  .trip__information-column {
    flex: 1 1;
    margin: 0;
    text-align: left;
  }
}

.trip__information-title {
  font-weight: bold;
  max-width: 300px;
  margin: 30px auto 10px;
}

.trip__information-cta {
  max-width: 300px;
  margin: 0 auto;
}

.trip__information-link {
  color: #009acd;
  font-weight: bold;
  font-size: 20px;
}

.trip__header {
  background-color: #ebebeb;
  border-radius: 3px;
  padding: 15px;
  font-weight: bold;
}
@media screen and (min-width: 1200px) {
  .trip__header {
    font-size: 18px;
  }
}
.trip__header a {
  float: right;
}
.trip__hero {
  background-position: center;
  background-size: cover;
  position: relative;
  color: #ffffff;
  padding: 30px 0;
}
.trip__hero:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}
.trip__hero > div {
  position: relative;
}
@media screen and (min-width: 1200px) {
  .trip__hero {
    order: -1;
    padding: 50px 0;
  }
}

.trip__hero-content {
  text-align: center;
  text-shadow: 0 0.5px 2px rgba(0, 0, 0, 0.8);
}
.trip__hero-content h1 {
  margin: 0 0 20px;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.8);
}
@media screen and (min-width: 1200px) {
  .trip__hero-content h1 {
    margin: 0;
  }
}
.trip__hero-content h1 span {
  text-shadow: 0 0.5px 2px rgba(0, 0, 0, 0.8);
}
@media screen and (min-width: 1200px) {
  .trip__hero-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1200px) {
  .trip__hero-title {
    text-align: left;
  }
}

.trip__hero-subheading {
  display: block;
  font-size: 16px;
}
@media screen and (min-width: 1200px) {
  .trip__hero-subheading {
    display: inline-block;
    font-size: 20px;
  }
}

.trip__hero-cta {
  text-align: center;
  margin-bottom: -55px;
}
.trip__hero-cta.hide-mobile {
  display: none;
}
@media screen and (min-width: 1200px) {
  .trip__hero-cta {
    margin-bottom: 0;
    text-align: left;
  }
  .trip__hero-cta.hide-mobile {
    display: block;
  }
}

.trip__hero-btn:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 16px;
  margin-right: 7px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%23ffffff'%3E%3Cpath d='M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.trip__hero-ttl {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
@media screen and (min-width: 1200px) {
  .trip__hero-ttl {
    margin-top: 0;
    justify-content: flex-end;
  }
}
.trip__hero-ttl-number {
  font-size: 3rem;
  margin-right: 10px;
  font-weight: bold;
}
.trip__hero-ttl-text {
  text-align: left;
  width: 90px;
  font-size: 18px;
}
.secnav-container {
  background-color: #ffffff;
}
@media screen and (max-width: 1200px) {
  .secnav-container {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    z-index: 1;
  }
}
@media screen and (min-width: 1200px) {
  .secnav-container {
    border-bottom: 4px solid #f8f8f8;
  }
}

.secnav-content {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 1200px) {
  .secnav-content {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0;
    justify-content: flex-start;
  }
}

.secnav-link {
  margin: 0 5px;
  padding: 10px;
  color: #858585;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 4px solid transparent;
  letter-spacing: 1px;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  flex: 1 1;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
}
@media screen and (max-width: 1200px) {
  .secnav-link {
    min-height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
.secnav-link:first-child {
  margin-left: 0;
}
.secnav-link:last-child {
  margin-right: 0;
}
@media screen and (min-width: 1200px) {
  .secnav-link {
    margin: 0 10px;
    flex: 0 1;
    font-size: 18px;
    margin-bottom: -4px;
    text-transform: none;
    color: #1e1e1c;
    padding: 1rem;
  }
  .secnav-link:hover {
    opacity: 0.5;
  }
}
.secnav-link.is-active {
  color: #1e1e1c;
  border-bottom-color: #ffdc0d;
}
.trip__notifications {
  max-width: 600px;
  padding-top: 15px;
  margin: 0 auto;
  width: 100%;
}
.trip__notifications-row {
  margin-bottom: 15px;
  padding: 0 15px 15px 15px;
  border-bottom: 1px solid #ebebeb;
}
.trip__notifications-row-date {
  color: #3d3d3d;
  font-size: 0.8rem;
  margin-top: 5px;
}
.trip__notifications-row-date:before {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='bell' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' class='svg-inline--fa fa-bell fa-w-14 fa-7x'%3E%3Cpath fill='%233d3d3d' d='M439.39 362.29c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71zM67.53 368c21.22-27.97 44.42-74.33 44.53-159.42 0-.2-.06-.38-.06-.58 0-61.86 50.14-112 112-112s112 50.14 112 112c0 .2-.06.38-.06.58.11 85.1 23.31 131.46 44.53 159.42H67.53zM224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64z' class=''%3E%3C/path%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  width: 0.9rem;
  height: 0.6rem;
  display: inline-block;
}
.trip__bottomNotice {
  background-color: #fff9cb;
  color: #1e1e1c;
  text-align: center;
  padding: 5px;
  width: 100%;
  margin-bottom: 15px;
}
.trip__bottomNotice span {
  color: #47a404;
  font-weight: bold;
}
.trip__summary {
  background-color: #ecf5e5;
  padding: 20px;
  margin: 40px 0 30px;
  text-align: center;
}
.trip__summary h2 {
  margin: 0;
}
@media screen and (min-width: 1200px) {
  .trip__summary {
    padding: 20px 30px;
    flex: 1 1;
    margin: 0;
    display: flex;
    flex-direction: row;
  }
}

.trip__summary-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  text-align: left;
}
@media screen and (min-width: 1200px) {
  .trip__summary-title {
    justify-content: center;
  }
}

.trip__summary-column + .trip__summary-column {
  margin-top: 15px;
}
@media screen and (min-width: 1200px) {
  .trip__summary-column {
    width: 50%;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
  }
  .trip__summary-column + .trip__summary-column {
    margin-left: 40px;
    border-left: 1px solid #d4d4d4;
    padding-left: 40px;
    margin-top: 0;
  }
}

.trip__summary-paid {
  text-align: center;
  font-size: 12px;
  margin: 2px 5px;
  padding: 2px 5px;
  border: 1px solid #47a404;
  color: #47a404;
}

.trip__summary-row {
  display: flex;
  margin-bottom: 10px;
  text-align: left;
}

.trip__summary-help {
  color: #858585;
  font-size: 12px;
}

.trip__summary-confirmation {
  color: #1e1e1c;
  font-size: 16px;
  font-weight: bold;
  background-color: #ffffff;
}
.trip__summary-confirmation:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 16px;
  margin-left: 7px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%231e1e1c'%3E%3Cpath d='M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.trip__summary-confirmation:hover {
  color: #ffffff;
}
.trip__summary-confirmation:hover:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%23ffffff'%3E%3Cpath d='M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.trip__summary-spacer {
  flex: 2 1;
  border-bottom: 1px dashed #858585;
  margin: 0 5px;
  height: 1rem;
}

.trip__summary-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.trip__summary-buttons a {
  margin-top: 15px;
}
@media screen and (min-width: 1200px) {
  .trip__summary-buttons {
    flex-grow: 1;
  }
}

.trip__summary-hint {
  font-size: 12px;
  color: #858585;
  margin-top: 10px;
}
.trip__flight h2 {
  margin: 0 0 10px;
  font-weight: normal;
  color: #004b85;
}
@media screen and (min-width: 1200px) {
  .trip__flight {
    width: 50%;
    border-right: 1px solid #ebebeb;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.trip__flight-row {
  padding: 0 30px;
}
@media screen and (min-width: 1200px) {
  .trip__flight-row {
    display: flex;
  }
}

.trip__flight-information {
  padding: 20px 0;
}
@media screen and (min-width: 1200px) {
  .trip__flight-information {
    padding: 10px 20px;
  }
}

.trip__flight-header {
  padding-left: 25px;
  position: relative;
}
.trip__flight-header:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 16px;
  background-position: top 3px center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%231e1e1c'%3E%3Cpath d='M624 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM80.55 341.27c6.28 6.84 15.1 10.72 24.33 10.71l130.54-.18a65.62 65.62 0 0 0 29.64-7.12l290.96-147.65c26.74-13.57 50.71-32.94 67.02-58.31 18.31-28.48 20.3-49.09 13.07-63.65-7.21-14.57-24.74-25.27-58.25-27.45-29.85-1.94-59.54 5.92-86.28 19.48l-98.51 49.99-218.7-82.06a17.799 17.799 0 0 0-18-1.11L90.62 67.29c-10.67 5.41-13.25 19.65-5.17 28.53l156.22 98.1-103.21 52.38-72.35-36.47a17.804 17.804 0 0 0-16.07.02L9.91 230.22c-10.44 5.3-13.19 19.12-5.57 28.08l76.21 82.97z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.trip__flight-header.is-arrival:before {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.trip__flight-date {
  font-weight: bold;
}

.trip__flight-flight {
  color: #858585;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
}

.trip__flight-timeline {
  border-left: 1px dashed #979797;
  padding: 0 20px;
  margin: 20px 4px 5px;
  position: relative;
}

.trip__flight-departure:before,
.trip__flight-arrival:before {
  content: "";
  height: 11px;
  width: 11px;
  position: absolute;
  background-color: #ffffff;
  border: 2px solid #979797;
  border-radius: 50%;
  left: -6px;
}

.trip__flight-departure:before {
  top: 0;
}

.trip__flight-arrival:before {
  bottom: 0;
}

.trip__flight-airport.is-departure {
  -webkit-transform: translate(0px, -4px);
          transform: translate(0px, -4px);
}
.trip__flight-airport.is-arrival {
  -webkit-transform: translate(0px, 4px);
          transform: translate(0px, 4px);
}

.trip__flight-duration {
  font-size: 12px;
  color: #858585;
  margin-bottom: 30px;
}

.trip__flight-luggage {
  padding: 10px;
  background-color: #f8f8f8;
  margin: 0 0 20px;
}
@media screen and (min-width: 1200px) {
  .trip__flight-luggage {
    margin: 20px 0 0;
    padding: 10px 20px;
  }
}

.trip__flight-luggage-help {
  font-size: 12px;
  color: #858585;
}

.trip__flight-add {
  color: #009acd;
  font-weight: bold;
}

.trip__flight-bag {
  margin: 15px;
}

.trip__flight-ski {
  margin: 15px;
}

.trip__flight-summary {
  font-weight: bold;
}
.trip__travellers {
  margin: 10px 0;
}
.trip__travellers h2 {
  margin: 0 0 10px;
  font-weight: normal;
  color: #004b85;
}
@media screen and (min-width: 1200px) {
  .trip__travellers {
    flex: 1 1;
    padding: 0;
    margin: 0;
  }
}

.trip__travellers-person {
  margin: 30px 20px;
  display: block;
}
.trip__travellers-person:first-of-type {
  margin-top: 0;
}
@media screen and (min-width: 1200px) {
  .trip__travellers-person {
    display: none;
  }
}

.trip__travellers-table {
  width: calc(100% - 15px * 2);
  border-collapse: collapse;
  margin: 0 15px;
  display: none;
}
@media screen and (min-width: 1200px) {
  .trip__travellers-table {
    display: table;
  }
}

.trip__travellers-row td {
  border-bottom: 1px solid #ebebeb;
  padding: 15px;
}

.trip__travellers-label {
  flex: 2 1;
}

.trip__travellers-value {
  font-weight: bold;
  flex: 3 1;
}
.trip__destination {
  margin: 10px 0;
}
.trip__destination h2 {
  margin: 0 0 10px;
  font-weight: normal;
  color: #004b85;
}
@media screen and (min-width: 1200px) {
  .trip__destination {
    margin: 0 0 0 50px;
    width: 50%;
    flex-grow: 0;
  }
}

.trip__destination-content {
  margin: 0 20px;
}
@media screen and (min-width: 1200px) {
  .trip__destination-content {
    margin: 0;
  }
}

.trip__destination-row {
  display: flex;
  margin-bottom: 7px;
}

.trip__destination-spacer {
  flex: 2 1;
  border-bottom: 1px dashed #858585;
  margin: 0 5px;
  height: 1rem;
}

.trip__destination-value {
  max-width: 70%;
}

.trip__destination-link {
  cursor: pointer;
}
.trip__destination-link-text {
  color: #009acd;
  text-decoration: underline;
}
.trip__includes {
  margin: 30px 0;
}
.trip__includes h2 {
  margin: 0;
  font-weight: normal;
  color: #004b85;
}
@media screen and (min-width: 1200px) {
  .trip__includes {
    flex: 1 1;
    padding: 0;
    margin: 0;
  }
}

.trip__includes-header {
  display: flex;
  align-items: center;
  margin: 0 0 10px;
}
.trip__includes-header h2 {
  flex: 1 1;
}

.trip__includes-addons {
  color: #858585;
  font-weight: bold;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.trip__includes-addons:hover {
  opacity: 0.5;
}

.trip__includes-row {
  display: flex;
  margin: 7px 0;
  border: 1px solid #ebebeb;
  padding: 10px;
}
@media screen and (min-width: 1200px) {
  .trip__includes-row {
    display: none;
  }
}

.trip__includes-table {
  width: calc(100% - 15px * 2);
  border-collapse: collapse;
  margin: 0 15px;
  display: none;
}
@media screen and (min-width: 1200px) {
  .trip__includes-table {
    display: table;
  }
}

.trip__includes-table-row td {
  border-bottom: 1px solid #ebebeb;
  padding: 15px;
}

.trip__includes-title {
  flex: 1 1;
}

.trip__includes-name {
  letter-spacing: 1px;
}

.trip__includes-edit {
  color: #009acd;
  font-size: 12px;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.trip__includes-edit:hover {
  opacity: 0.5;
}
@media screen and (min-width: 1200px) {
  .trip__includes-edit {
    font-size: 16px;
    float: right;
  }
}

.trip__includes-prefix {
  font-weight: bold;
  text-align: right;
}

.trip__includes-price {
  font-size: 14px;
  color: #979797;
}

.trip__includes-cta {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.TripAfterInfo {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  margin-top: 15px;
  margin-bottom: -20px;
}
@media screen and (min-width: 1200px) {
  .TripAfterInfo {
    margin-top: 0;
    margin-bottom: -50px;
  }
}
.TripAfterInfo__image img {
  width: 100%;
}
.TripAfterInfo__content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 15px;
  color: #ffffff;
}
@media screen and (min-width: 1200px) {
  .TripAfterInfo__content {
    padding: 50px;
  }
}
.TripAfterInfo__content--vertical-top {
  justify-content: flex-start;
}
.TripAfterInfo__content--vertical-center {
  justify-content: center;
}
.TripAfterInfo__content--vertical-bottom {
  justify-content: flex-end;
}
.TripAfterInfo__content--horizontal-left {
  align-items: flex-start;
}
.TripAfterInfo__content--horizontal-center {
  align-items: center;
}
.TripAfterInfo__content--horizontal-right {
  align-items: flex-end;
}
.TripAfterInfo__title {
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.8);
  margin: 0;
  order: 1;
}
.TripAfterInfo__text {
  text-shadow: 0 0.5px 2px rgba(0, 0, 0, 0.8);
  order: 2;
}
.TripAfterInfo__cta {
  border: 0;
  outline: 0;
  cursor: pointer;
  color: #ffffff;
  background-color: #47a404;
  order: 3;
  padding: 10px 15px;
  height: auto;
  height: initial;
}
.TripAfterInfo__cta:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.TripAfterInfo__cta--thunderbird {
  color: #ffffff;
  background-color: #cf1011;
}
.TripAfterInfo__cta--turbo {
  color: #1e1e1c;
  background-color: #ffec00;
}
.TripAfterInfo__cta--limeade {
  color: #ffffff;
  background-color: #47a404;
}
.TripAfterInfo__cta--pos-abovecontent {
  order: 0;
}
.trip__addonGroupTitle {
  background-color: #ebebeb;
  border-radius: 3px;
  padding: 10px 15px;
  font-weight: bold;
  margin: 30px -15px 15px -15px;
}
.trip__addonGroup:last-of-type {
  margin-bottom: 50px;
}

@media screen and (max-width: 1200px) {
  .trip__addons--slim {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .trip__addons {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 70px;
  }
}

.trip__addon {
  padding: 15px 0;
}
@media screen and (min-width: 1200px) {
  .trip__addon {
    padding: 10px;
    flex: 0 1 33%;
  }
}
.trip__addon--slim {
  padding: 0;
}
@media screen and (min-width: 1200px) {
  .trip__addon--slim {
    padding: 10px;
    flex: 0 1 33%;
  }
}

.trip__addon-content {
  height: 100%;
  position: relative;
  border: 1px solid #ebebeb;
}
@media screen and (min-width: 1200px) {
  .trip__addon-content {
    display: flex;
    flex-direction: column;
  }
}

.trip__addon-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  flex: 1 1;
  padding-top: 20px;
  padding-left: 20px;
}
.trip__addon--slim .trip__addon-body {
  padding-top: 7px;
  padding-left: 7px;
}
@media screen and (max-width: 1200px) {
  .trip__addon--slim .trip__addon-body {
    height: 100%;
  }
}

.trip__addon-deadline {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px 5px;
  background: #cf1011;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.trip__addon-title {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
.trip__addon--slim .trip__addon-title {
  padding-right: 7px;
}

.trip__addon-name {
  font-weight: bold;
  flex: 1 1;
}

.trip__addon-image {
  background-size: cover;
  background-position: center;
  height: 0px;
  width: 100%;
  padding-bottom: 40%;
}

.trip__addon-description {
  padding: 0 0 10px 30px;
  margin: 0 20px 0 0;
  font-size: 12px;
  color: #858585;
  list-style: none;
}

.trip__addon-usp {
  margin: 7px 0;
  position: relative;
}
.trip__addon-usp:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -27px;
  height: 16px;
  width: 16px;
  margin-right: 4px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%2347a404'%3E%3Cpath d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.trip__addon-cta {
  margin-top: auto;
  text-align: right;
}

.trip__addon-link {
  background-color: #47a404;
  padding: 5px 25px;
  display: inline-block;
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  -webkit-transition: background-color 0.25s;
  transition: background-color 0.25s;
}
.trip__addon-cta--slim .trip__addon-link {
  background-color: #979797;
}
.trip__addon-link:hover {
  background-color: #3d3d3d;
}
.trip__addon-link:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 16px;
  margin-left: 4px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%23ffffff'%3E%3Cpath d='M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.trip__addon-duration {
  margin-left: 5px;
  margin-top: 3px;
  font-size: 14px;
}
.trip__faq {
  padding: 50px 0;
  text-align: center;
}

.trip__faq-link {
  text-decoration: none;
  color: #009acd;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.trip__faq-link:hover {
  opacity: 0.5;
}
.trip__faq-link:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 16px;
  margin-left: 3px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%23009acd'%3E%3Cpath d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}


.skiing__lifts-image {
  text-align: center;
}
.linklist__list {
  margin: 30px 0 0;
}
@media screen and (min-width: 1200px) {
  .linklist__list {
    display: flex;
    flex-wrap: wrap;
  }
}

.linklist__wrapper {
  margin-bottom: -1px;
}
@media screen and (min-width: 1200px) {
  .linklist__wrapper {
    padding: 10px;
    flex: 0 1 33.33%;
  }
}

.linklist__link {
  display: flex;
  justify-content: space-between;
  color: #1e1e1c;
  font-weight: bold;
  text-decoration: none;
  padding: 10px 25px 10px 15px;
  border: 1px solid #ebebeb;
  position: relative;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.linklist__link:hover {
  opacity: 0.5;
}
.linklist__link:after {
  content: "";
  display: inline-block;
  position: absolute;
  right: 6px;
  top: 12px;
  height: 16px;
  width: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%231e1e1c'%3E%3Cpath d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.linklist__link-external {
  flex-shrink: 0;
  font-weight: normal;
  font-size: 14px;
  color: #858585;
  padding: 0px 10px;
  border: 1px solid #858585;
  border-radius: 3px;
}
.mapimage__list {
  min-height: 200px;
  height: 60vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.mapimage__list.is-small {
  height: 250px;
}
@media screen and (max-width: 1200px) {
  .mapimage__list + .app-content .linklist__list {
    margin-top: 0;
  }
}

.mapimage__trigger {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mapimage__trigger-icon {
  width: 70px;
  height: 70px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-image: radial-gradient(circle, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0) 70%);
}
.mapimage__trigger-icon:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%23ffffff'%3E%3Cpath d='M304 192v32c0 6.6-5.4 12-12 12h-56v56c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-56h-56c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h56v-56c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v56h56c6.6 0 12 5.4 12 12zm201 284.7L476.7 505c-9.4 9.4-24.6 9.4-33.9 0L343 405.3c-4.5-4.5-7-10.6-7-17V372c-35.3 27.6-79.7 44-128 44C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 48.3-16.4 92.7-44 128h16.3c6.4 0 12.5 2.5 17 7l99.7 99.7c9.3 9.4 9.3 24.6 0 34zM344 208c0-75.2-60.8-136-136-136S72 132.8 72 208s60.8 136 136 136 136-60.8 136-136z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
@media screen and (min-width: 1200px) {
  .mapimage__trigger-icon {
    background-position: bottom 15px right;
  }
}

.mapimage__open-in-map-icon {
  width: 70px;
  height: 70px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.mapimage__open-in-map-icon:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gU3ZnIFZlY3RvciBJY29ucyA6IGh0dHA6Ly93d3cub25saW5ld2ViZm9udHMuY29tL2ljb24gLS0+DQo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwMCAxMDAwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMDAwIDEwMDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPG1ldGFkYXRhPiBTdmcgVmVjdG9yIEljb25zIDogaHR0cDovL3d3dy5vbmxpbmV3ZWJmb250cy5jb20vaWNvbiA8L21ldGFkYXRhPg0KPGc+PHBhdGggZD0iTTUwMS4yLDI0Ni42YzgxLjgsMCwxNDguNCw2Ni42LDE0OC40LDE0OC40YzAsODEuOC02Ni42LDE0OC40LTE0OC40LDE0OC40Yy04MS44LDAtMTQ4LjQtNjYuNi0xNDguNC0xNDguNEMzNTIuOCwzMTMuMiw0MTkuNCwyNDYuNiw1MDEuMiwyNDYuNiBNNTAxLjIsMTg1Yy0xMTUuOCwwLTIxMCw5NC4yLTIxMCwyMTBjMCwxMTUuOCw5NC4yLDIxMCwyMTAsMjEwYzExNS44LDAsMjEwLTk0LjIsMjEwLTIxMEM3MTEuMiwyNzkuMiw2MTcsMTg1LDUwMS4yLDE4NUw1MDEuMiwxODV6Ii8+PHBhdGggZD0iTTUwMCw3MS42YzE3OC4zLDAsMzIzLjQsMTQ1LjEsMzIzLjQsMzIzLjRjMCwyNS40LTguMiw3MS41LTQ3LjMsMTQ3LjNjLTI3LjQsNTMuMS02NiwxMTMuNC0xMTQuOSwxNzkuMUM1OTcuMiw4MDcuNiw1MzEuNiw4ODEuNSw1MDAsOTE2Yy0zMS42LTM0LjUtOTcuMi0xMDguNC0xNjEuMi0xOTQuNmMtNDguOC02NS43LTg3LjUtMTI2LTExNC45LTE3OS4xYy0zOS4xLTc1LjgtNDcuMy0xMjItNDcuMy0xNDcuM0MxNzYuNiwyMTYuNywzMjEuNyw3MS42LDUwMCw3MS42TDUwMCw3MS42IE01MDAsMTBDNTAwLDEwLDUwMCwxMCw1MDAsMTBjLTIxMi4zLDAtMzg1LDE3Mi43LTM4NSwzODVjMCwyMDQuOSwzNDUsNTY4LjgsMzU5LjcsNTg0LjFjNi45LDcuMiwxNi4xLDEwLjksMjUuMywxMC45YzAsMCwwLDAsMCwwYzkuMiwwLDE4LjQtMy42LDI1LjMtMTAuOUM1NDAsOTYzLjgsODg1LDU5OS45LDg4NSwzOTVDODg1LDE4Mi43LDcxMi4zLDEwLDUwMCwxMEw1MDAsMTB6Ii8+PC9nPg0KPC9zdmc+);
}
@media screen and (min-width: 1200px) {
  .mapimage__open-in-map-icon {
    background-position: bottom 15px right;
  }
}

.webcams {
  padding: 15px;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
}
@media screen and (min-width: 1200px) {
  .webcams {
    padding: 15px 0;
  }
}
.webcams__htmlWrapper {
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .webcams__htmlWrapper {
    width: 33%;
    float: left;
    padding: 0 15px 0 0;
  }
}
.webcams__htmlWrapper > * {
  max-width: 100%;
}
.webcams__imageWrapper {
  width: 100%;
  margin-bottom: 15px;
  outline: 0;
}
@media screen and (min-width: 1200px) {
  .webcams__imageWrapper {
    width: 33%;
    float: left;
  }
}
.webcams__image {
  width: 100%;
}
.webcams__imageButton {
  border: none;
  background: transparent;
  position: relative;
  outline: 0;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: inline-block;
}
@media screen and (min-width: 1200px) {
  .webcams__imageButton {
    margin: 0 15px 0 0;
  }
}
.webcams__imageTitle {
  position: absolute;
  bottom: 15px;
  left: 15px;
  font-size: 16px;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.8);
  color: #ffffff;
}
@media screen and (min-width: 1200px) {
  .webcams__imageTitle {
    font-size: 20px;
  }
}
.webcams__modal {
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  height: 100%;
  background-color: #ffffff;
  outline: 0;
}
@media screen and (min-width: 1200px) {
  .webcams__modal {
    height: 700px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    position: relative;
  }
}
.webcams__modalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 300;
}
.webcams__modalHeader {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.webcams__modalHeader button {
  background: transparent;
  border: 0;
  font-size: 1.5rem;
  min-width: 40px;
  min-height: 40px;
  margin-right: 0.5rem;
  cursor: pointer;
  position: relative;
}
.webcams__modalHeader button:before, .webcams__modalHeader button:after {
  content: "";
  width: 2px;
  height: 14px;
  display: block;
  background-color: #1e1e1c;
  position: absolute;
  left: 20px;
  top: 12px;
}
.webcams__modalHeader button:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.webcams__modalHeader button:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.webcams__iframe {
  width: 100%;
  height: calc(100% - 50px);
  border: 0;
}
.webcams__link {
  text-decoration: none;
  color: #009acd;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.webcams__link:hover {
  opacity: 0.5;
}
.webcams__link:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 16px;
  margin-left: 3px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%23009acd'%3E%3Cpath d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.SkiingWebcam__iframe {
  width: 100%;
  min-height: 70vw;
  border: 0;
  margin-top: 15px;
}


.hiking__lifts-image {
  text-align: center;
}


.location-s__contact {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  margin-top: 10px;
  margin-bottom: -20px;
}

.location-s__contact-image {
  height: 100px;
  width: 110px;
  padding: 10px 15px;
}

.location-s__contact-file {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.location-s__contact-content {
  flex: 1 1;
  padding-left: 15px;
  letter-spacing: 1px;
}
@media screen and (min-width: 1200px) {
  .location-s__contact-content {
    flex: unset;
  }
}

.location-s__contact-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.location-s__contact-link {
  color: #009acd;
  font-weight: bold;
}
.location-l__hero {
  background-position: center;
  background-size: cover;
  position: relative;
  color: #ffffff;
  padding: 30px 0;
  text-align: center;
}
.location-l__hero:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}
.location-l__hero > div {
  position: relative;
  text-shadow: 0 0.5px 2px rgba(0, 0, 0, 0.8);
}
@media screen and (min-width: 1200px) {
  .location-l__hero {
    order: -1;
    padding: 50px 0;
  }
}

.location-l__contact {
  background-color: #e9f7ff;
  padding: 20px 50px;
  margin: -20px auto 20px;
  max-width: 320px;
  text-align: center;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .location-l__contact {
    max-width: 500px;
    padding: 40px 50px;
  }
}

.location-l__contact-title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
}

.location-l__contact-phone {
  color: #009acd;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  font-size: 20px;
}

.location-l__contact-email {
  display: block;
  margin-bottom: 15px;
}

.location-l__leaders {
  display: flex;
  flex-wrap: wrap;
}

.location-l__leader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  flex: 1 1 50%;
}
@media screen and (min-width: 1200px) {
  .location-l__leader {
    flex: 1 1 20%;
  }
}

.location-l__leader-image {
  height: 120px;
  width: 140px;
  padding: 5px 15px;
}
@media screen and (min-width: 1200px) {
  .location-l__leader-image {
    height: 90px;
    width: 100px;
    padding: 10px 15px;
  }
}

.location-l__leader-file {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ebebeb;
}

.location-l__leader-name {
  font-weight: bold;
}

.location-l__leader-leader {
  font-weight: bold;
  font-size: 12px;
  color: #3d3d3d;
  text-transform: uppercase;
}

.location-l__back {
  font-weight: bold;
  text-decoration: none;
  color: #0081c6;
  margin-bottom: 20px;
  display: block;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.location-l__back:hover {
  opacity: 0.5;
}
.location-l__back:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 16px;
  margin-right: 5px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%231e1e1c'%3E%3Cpath d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.location-l__leader-language {
  width: 26px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border: 1px solid #979797;
}
.location-l__leader-language + .location-l__leader-language {
  margin-left: 5px;
}
.location-l__leader-language-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2px;
}
.location-l__leader-language__se {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgwIiB3aWR0aD0iNjQwIiB2aWV3Qm94PSIwIDAgNjQwIDQ4MCI+CiAgPGRlZnM+CiAgICA8Y2xpcFBhdGggaWQ9ImEiPgogICAgICA8cGF0aCBmaWxsLW9wYWNpdHk9Ii42NyIgZD0iTS01My40MjEgMGg2ODIuNjd2NTEyaC02ODIuNjd6Ii8+CiAgICA8L2NsaXBQYXRoPgogIDwvZGVmcz4KICA8ZyBjbGlwLXBhdGg9InVybCgjYSkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUwLjA4Mikgc2NhbGUoLjkzNzUpIj4KICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLXdpZHRoPSIxcHQiPgogICAgICA8cGF0aCBmaWxsPSIjMDA2YWE3IiBkPSJNLTEyMS4xMDMuMzAyaDI1NlYyMDUuMWgtMjU2ek0tMTIxLjEwMyAzMDcuMTc4aDI1NnYyMDQuOGgtMjU2eiIvPgogICAgICA8cGF0aCBmaWxsPSIjZmVjYzAwIiBkPSJNLTEyMS4xMDMgMjA0Ljk4NGgyNTZ2MTAyLjRoLTI1NnoiLz4KICAgICAgPHBhdGggZmlsbD0iI2ZlY2MwMCIgZD0iTTEzMy44NDMuMDFoMTAyLjR2NTExLjk5N2gtMTAyLjR6Ii8+CiAgICAgIDxwYXRoIGZpbGw9IiNmZWNjMDAiIGQ9Ik0yMzIuOTk1IDIwNS4wMTNoNDYwLjc5OHYxMDIuNEgyMzIuOTk1eiIvPgogICAgICA8cGF0aCBmaWxsPSIjMDA2YWE3IiBkPSJNMjM2LjE1NSAzMDcuMjA4aDQ2MC43OTd2MjA0Ljc5OUgyMzYuMTU1ek0yMzYuMTU1LjMwMmg0NjAuNzk3VjIwNS4xSDIzNi4xNTV6Ii8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K);
}
.location-l__leader-language__no {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgwIiB3aWR0aD0iNjQwIiB2aWV3Qm94PSIwIDAgNjQwIDQ4MCI+CiAgPHBhdGggZmlsbD0iI2VmMmIyZCIgZD0iTTAgMGg2NDB2NDgwSDB6Ii8+CiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE4MCAwaDEyMHY0ODBIMTgweiIvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0wIDE4MGg2NDB2MTIwSDB6Ii8+CiAgPHBhdGggZmlsbD0iIzAwMjg2OCIgZD0iTTIxMCAwaDYwdjQ4MGgtNjB6Ii8+CiAgPHBhdGggZmlsbD0iIzAwMjg2OCIgZD0iTTAgMjEwaDY0MHY2MEgweiIvPgo8L3N2Zz4K);
}
.location-l__leader-language__fi {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgwIiB3aWR0aD0iNjQwIiB2aWV3Qm94PSIwIDAgNjQwIDQ4MCI+CiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGg2NDB2NDgwSDB6Ii8+CiAgPHBhdGggZmlsbD0iIzAwMzU4MCIgZD0iTTAgMTc0LjU0NWg2NDB2MTMwLjkwOUgweiIvPgogIDxwYXRoIGZpbGw9IiMwMDM1ODAiIGQ9Ik0xNzUuNDU1IDBoMTMwLjkwOXY0ODBIMTc1LjQ1NXoiLz4KPC9zdmc+Cg==);
}

.location-c__hero {
  background-position: center;
  background-size: cover;
  position: relative;
  color: #ffffff;
  padding: 30px 15px;
  text-align: left;
  min-height: 250px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.location-c__hero:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}
.location-c__hero > div {
  position: relative;
}
@media screen and (min-width: 1200px) {
  .location-c__hero {
    padding: 50px 0;
  }
}

.location-c__hero-content {
  margin: 0 auto;
  max-width: 700px;
  text-shadow: 0 0.5px 2px rgba(0, 0, 0, 0.8);
}

.location-c__hero-title {
  margin: 0;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.8);
}

.location-c__content {
  clear: both;
  margin: 15px auto;
  max-width: 700px;
}

.location-c__back {
  margin: 20px auto;
  max-width: 700px;
}

.location-c__back-link {
  align-items: center;
  color: #0081c6;
  display: flex;
  font-weight: bold;
  text-decoration: none;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.location-c__back-link:hover {
  opacity: 0.5;
}
.location-c__back-link:before {
  content: "";
  height: 16px;
  width: 16px;
  margin-right: 5px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%230081c6'%3E%3Cpath d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.location-c__web {
  color: #009acd;
  font-weight: bold;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  word-break: break-word;
}
.location-c__web:hover {
  opacity: 0.5;
}

.location-c__address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
@media screen and (min-width: 1200px) {
  .location-c__address {
    justify-content: flex-start;
  }
}

.location-c__address-line {
  font-weight: bold;
  max-width: 150px;
}
@media screen and (min-width: 1200px) {
  .location-c__address-line {
    max-width: none;
    margin-right: 15px;
  }
}

.location-c__directions {
  text-decoration: none;
  font-size: 12px;
  color: #858585;
}
.location-c__directions:before {
  content: "";
  display: block;
  height: 26px;
  width: 26px;
  margin: 5px auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gU3ZnIFZlY3RvciBJY29ucyA6IGh0dHA6Ly93d3cub25saW5ld2ViZm9udHMuY29tL2ljb24gLS0+DQo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwMCAxMDAwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMDAwIDEwMDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPG1ldGFkYXRhPiBTdmcgVmVjdG9yIEljb25zIDogaHR0cDovL3d3dy5vbmxpbmV3ZWJmb250cy5jb20vaWNvbiA8L21ldGFkYXRhPg0KPGc+PHBhdGggZD0iTTUwMS4yLDI0Ni42YzgxLjgsMCwxNDguNCw2Ni42LDE0OC40LDE0OC40YzAsODEuOC02Ni42LDE0OC40LTE0OC40LDE0OC40Yy04MS44LDAtMTQ4LjQtNjYuNi0xNDguNC0xNDguNEMzNTIuOCwzMTMuMiw0MTkuNCwyNDYuNiw1MDEuMiwyNDYuNiBNNTAxLjIsMTg1Yy0xMTUuOCwwLTIxMCw5NC4yLTIxMCwyMTBjMCwxMTUuOCw5NC4yLDIxMCwyMTAsMjEwYzExNS44LDAsMjEwLTk0LjIsMjEwLTIxMEM3MTEuMiwyNzkuMiw2MTcsMTg1LDUwMS4yLDE4NUw1MDEuMiwxODV6Ii8+PHBhdGggZD0iTTUwMCw3MS42YzE3OC4zLDAsMzIzLjQsMTQ1LjEsMzIzLjQsMzIzLjRjMCwyNS40LTguMiw3MS41LTQ3LjMsMTQ3LjNjLTI3LjQsNTMuMS02NiwxMTMuNC0xMTQuOSwxNzkuMUM1OTcuMiw4MDcuNiw1MzEuNiw4ODEuNSw1MDAsOTE2Yy0zMS42LTM0LjUtOTcuMi0xMDguNC0xNjEuMi0xOTQuNmMtNDguOC02NS43LTg3LjUtMTI2LTExNC45LTE3OS4xYy0zOS4xLTc1LjgtNDcuMy0xMjItNDcuMy0xNDcuM0MxNzYuNiwyMTYuNywzMjEuNyw3MS42LDUwMCw3MS42TDUwMCw3MS42IE01MDAsMTBDNTAwLDEwLDUwMCwxMCw1MDAsMTBjLTIxMi4zLDAtMzg1LDE3Mi43LTM4NSwzODVjMCwyMDQuOSwzNDUsNTY4LjgsMzU5LjcsNTg0LjFjNi45LDcuMiwxNi4xLDEwLjksMjUuMywxMC45YzAsMCwwLDAsMCwwYzkuMiwwLDE4LjQtMy42LDI1LjMtMTAuOUM1NDAsOTYzLjgsODg1LDU5OS45LDg4NSwzOTVDODg1LDE4Mi43LDcxMi4zLDEwLDUwMCwxMEw1MDAsMTB6Ii8+PC9nPg0KPC9zdmc+);
}

.location-c__directions-container {
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.location-c__directions-container:hover {
  opacity: 0.5;
}

@media screen and (min-width: 1200px) {
  .location-c__tripAdvisor {
    width: 40%;
  }
  .location-c__tripAdvisor + .location-c__tripAdvisor {
    padding-left: 4rem;
  }
  .location-c__tripAdvisorColumns {
    display: flex;
    justify-content: space-evenly;
  }
}
.location-c__tripAdvisor dl {
  display: flex;
  flex-wrap: wrap;
}
.location-c__tripAdvisor dt {
  width: 40%;
  padding: 3px 0;
}
.location-c__tripAdvisor dd {
  width: 60%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.location-c__tripAdvisorRating {
  background-color: #ebebeb;
  padding: 5px 15px;
  margin: 30px -15px 15px -15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.location-c__tripAdvisorRatingInfo {
  font-size: 12px;
  text-align: end;
}
.location-c__tripAdvisorRatingInfo img {
  position: relative;
  right: -10px;
}
.location-c__tripAdvisorRatingHeading {
  font-weight: bold;
  text-transform: uppercase;
}
.location-c__tripAdvisor .rating-item + .location-c__tripAdvisor .rating-item {
  margin-top: 0.5rem;
}
.location-c__tripAdvisor .rating-item__label {
  display: block;
}
.location-c__tripAdvisor .rating-item__score-container {
  display: flex;
  align-items: center;
  width: 100%;
}
.location-c__tripAdvisor .rating-item__bar {
  background-color: rgba(61, 61, 61, 0.15);
  border-radius: 2px;
  height: 10px;
  width: calc(100% - 46px);
}
.location-c__tripAdvisor .rating-item__bar-value {
  background-color: #00a680;
  border-radius: 2px;
  height: 100%;
}
.location-c__tripAdvisor .rating-item__score {
  font-weight: bold;
  margin-left: auto;
}


.location-ll__title {
  text-align: center;
}
.location-ll__title .location-ll__back {
  color: #0081c6;
}
.location-ll__title .location-ll__back:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%230081c6'%3E%3Cpath d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.location-ll__hero {
  background-position: center;
  background-size: cover;
  position: relative;
  color: #ffffff;
  padding: 30px 0;
  text-align: center;
  margin-bottom: -30px;
}
.location-ll__hero:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}
.location-ll__hero > div {
  position: relative;
  text-shadow: 0 0.5px 2px rgba(0, 0, 0, 0.8);
}
@media screen and (min-width: 1200px) {
  .location-ll__hero {
    order: -1;
    padding: 50px 0;
    margin-bottom: 0;
  }
}
.location-ll__hero .location-ll__back {
  color: #0081c6;
}
.location-ll__hero .location-ll__back:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%230081c6'%3E%3Cpath d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.location-ll__back {
  align-items: center;
  justify-content: center;
  color: #0081c6;
  display: flex;
  font-weight: bold;
  text-decoration: none;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.location-ll__back:hover {
  opacity: 0.5;
}
.location-ll__back:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 16px;
  margin-right: 5px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.activities__hero {
  padding: 40px 15px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.activities__hero:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
@media screen and (min-width: 1200px) {
  .activities__hero {
    order: -1;
    padding: 70px 0;
  }
}

.activities__hero-content {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  color: #ffffff;
  text-shadow: 0 0.5px 2px rgba(0, 0, 0, 0.8);
}

.activities__hero-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.8);
}

.activities__hero-description {
  font-weight: bold;
}

.activities__promotion {
  padding: 30px 15px;
  max-width: 600px;
  margin: 0 auto;
}

.activities__disclaimer {
  padding: 30px 15px;
  font-size: 14px;
  color: #858585;
  max-width: 500px;
  margin: 0 auto;
}

.activity__schedule-day {
  background-color: #ebebeb;
  color: #858585;
  font-size: 12px;
  text-transform: uppercase;
  padding: 10px 0;
  font-weight: bold;
}
.activity__schedule-day .app-content {
  max-width: 600px;
}
@media screen and (min-width: 1200px) {
  .activity__schedule-day {
    text-align: center;
  }
}
.activity__schedule-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 20px 45px 20px 15px;
  border-bottom: 1px solid #ebebeb;
  max-width: 500px;
  text-decoration: none;
  color: #1e1e1c;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.activity__schedule-item:hover {
  opacity: 0.5;
}
.activity__schedule-item:last-child {
  border: 0;
}
.activity__schedule-item:after {
  content: "";
  position: absolute;
  right: 10px;
  height: 16px;
  width: 16px;
  margin-right: 5px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%231e1e1c'%3E%3Cpath d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.activity__schedule-item-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: #1e1e1c;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.activity__schedule-time {
  font-size: 12px;
  color: #858585;
}

.activity__schedule-booking {
  flex-shrink: 0;
  font-size: 12px;
  color: #858585;
  padding: 3px 5px;
  border: 1px solid #47a404;
  border-radius: 3px;
}
.activity__schedule-booking--canceled {
  border-color: #d0021b;
}

.activity__usps {
  padding: 0 0 0 30px;
  margin: 0;
  font-size: 12px;
  color: #858585;
  list-style: none;
}

.activity__usp {
  margin: 7px 0;
  position: relative;
}
.activity__usp:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -27px;
  height: 16px;
  width: 16px;
  margin-right: 4px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%2347a404'%3E%3Cpath d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.activity-i__hero {
  background-position: center;
  background-size: cover;
  position: relative;
  color: #ffffff;
  padding: 30px 15px;
  text-align: left;
  min-height: 250px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.activity-i__hero:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}
.activity-i__hero > div {
  position: relative;
}
@media screen and (min-width: 1200px) {
  .activity-i__hero {
    padding: 50px 0;
  }
}

.activity-i__hero-content {
  margin: 0 auto;
  max-width: 700px;
  text-shadow: 0 0.5px 2px rgba(0, 0, 0, 0.8);
}

.activity-i__hero-title {
  margin: 0;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.8);
}

.activity-i__back {
  margin-top: 20px;
}

.activity-i__back-link {
  font-weight: bold;
  color: #1e1e1c;
  text-decoration: none;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.activity-i__back-link:hover {
  opacity: 0.5;
}
.activity-i__back-link:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 16px;
  margin-right: 5px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%231e1e1c'%3E%3Cpath d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.activity-i__meta {
  padding: 20px 0 0;
  font-size: 12px;
  color: #858585;
}

.activity-i__meta-line {
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
}

.activity-i__meta-label {
  flex: 0 0 75px;
  font-weight: bold;
  text-transform: uppercase;
}

.activity-i__meta-value {
  flex: 1 1;
  display: flex;
}

.activity-i__meta-info {
  padding-left: 4px;
  color: #009acd;
}

.activity-i__content {
  max-width: 600px;
}

.activity-i__book {
  margin-top: 30px;
  text-align: center;
}

.activity-i__price {
  font-size: 12px;
  color: #858585;
  font-weight: bold;
  text-transform: uppercase;
}

.activity-i__deadline {
  font-size: 12px;
  color: #d0021b;
}

.activity-i__canceled {
  color: #d0021b;
}

.activity-i__cta {
  background-color: #47a404;
  color: #ffffff;
  font-weight: bold;
  display: block;
  padding: 15px;
  text-decoration: none;
  -webkit-transition: background-color 0.25s;
  transition: background-color 0.25s;
  margin: 10px 0;
}
@media screen and (min-width: 1200px) {
  .activity-i__cta:hover {
    background-color: #3d3d3d;
  }
}
.activity-i__cta--disabled {
  background-color: #e4e4e4;
  color: #858585;
}
@media screen and (min-width: 1200px) {
  .activity-i__cta--disabled:hover {
    background-color: #e4e4e4;
  }
}

.activity-i__description {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.activity-i__usps {
  padding: 0 0 0 30px;
  margin: 1rem 0 -0.6rem 0;
  list-style: none;
}

.activity-i__usp {
  margin: 7px 0;
  position: relative;
}
.activity-i__usp:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -27px;
  height: 16px;
  width: 16px;
  margin-right: 4px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg stroke='STROKE' fill='%2347a404'%3E%3Cpath d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
