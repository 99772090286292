@import '../../../shared/_variables';
@import '../../../shared/_icons';

// TODO: Merge with tripHero? But with bottom text?
.location-c__hero {
  background-position: center;
  background-size: cover;
  position: relative;
  color: $c-white;
  padding: 30px $s-margin;
  text-align: left;
  min-height: 250px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  > div {
    position: relative;
  }

  @media screen and (min-width: $b-desktop) {
    padding: 50px 0;
  }
}

.location-c__hero-content {
  margin: 0 auto;
  max-width: 700px;
  text-shadow: 0 0.5px 2px rgba(0,0,0,.8);
}

.location-c__hero-title {
  margin: 0;
  text-shadow: 0 1px 5px rgba(0,0,0,.8);
}

.location-c__content {
  clear: both;
  margin: 15px auto;
  max-width: 700px;
}

.location-c__back {
  margin: 20px auto;
  max-width: 700px;
}

.location-c__back-link {
  align-items: center;
  color: $c-lochmara;
  display: flex;
  font-weight: bold;
  text-decoration: none;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.5;
  }

  &:before {
    content: '';
    height: 16px;
    width: 16px;
    margin-right: 5px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: icon('caret', $c-lochmara);
    transform: rotate(180deg);
  }
}

.location-c__web {
  color: $c-pacific;
  font-weight: bold;
  transition: opacity 0.25s;
  word-break: break-word;

  &:hover {
    opacity: 0.5;
  }
}

.location-c__address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;

  @media screen and (min-width: $b-desktop) {
    justify-content: flex-start;
  }
}

.location-c__address-line {
  font-weight: bold;
  max-width: 150px;

  @media screen and (min-width: $b-desktop) {
    max-width: none;
    margin-right: $s-margin;
  }
}

.location-c__directions {
  text-decoration: none;
  font-size: $f-small;
  color: $c-grey;
  // transition: opacity 0.25s;

  // &:hover {
  //   opacity: 0.5;
  // }

  &:before {
    content: '';
    display: block;
    height: 26px;
    width: 26px;
    margin: 5px auto;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gU3ZnIFZlY3RvciBJY29ucyA6IGh0dHA6Ly93d3cub25saW5ld2ViZm9udHMuY29tL2ljb24gLS0+DQo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwMCAxMDAwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMDAwIDEwMDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPG1ldGFkYXRhPiBTdmcgVmVjdG9yIEljb25zIDogaHR0cDovL3d3dy5vbmxpbmV3ZWJmb250cy5jb20vaWNvbiA8L21ldGFkYXRhPg0KPGc+PHBhdGggZD0iTTUwMS4yLDI0Ni42YzgxLjgsMCwxNDguNCw2Ni42LDE0OC40LDE0OC40YzAsODEuOC02Ni42LDE0OC40LTE0OC40LDE0OC40Yy04MS44LDAtMTQ4LjQtNjYuNi0xNDguNC0xNDguNEMzNTIuOCwzMTMuMiw0MTkuNCwyNDYuNiw1MDEuMiwyNDYuNiBNNTAxLjIsMTg1Yy0xMTUuOCwwLTIxMCw5NC4yLTIxMCwyMTBjMCwxMTUuOCw5NC4yLDIxMCwyMTAsMjEwYzExNS44LDAsMjEwLTk0LjIsMjEwLTIxMEM3MTEuMiwyNzkuMiw2MTcsMTg1LDUwMS4yLDE4NUw1MDEuMiwxODV6Ii8+PHBhdGggZD0iTTUwMCw3MS42YzE3OC4zLDAsMzIzLjQsMTQ1LjEsMzIzLjQsMzIzLjRjMCwyNS40LTguMiw3MS41LTQ3LjMsMTQ3LjNjLTI3LjQsNTMuMS02NiwxMTMuNC0xMTQuOSwxNzkuMUM1OTcuMiw4MDcuNiw1MzEuNiw4ODEuNSw1MDAsOTE2Yy0zMS42LTM0LjUtOTcuMi0xMDguNC0xNjEuMi0xOTQuNmMtNDguOC02NS43LTg3LjUtMTI2LTExNC45LTE3OS4xYy0zOS4xLTc1LjgtNDcuMy0xMjItNDcuMy0xNDcuM0MxNzYuNiwyMTYuNywzMjEuNyw3MS42LDUwMCw3MS42TDUwMCw3MS42IE01MDAsMTBDNTAwLDEwLDUwMCwxMCw1MDAsMTBjLTIxMi4zLDAtMzg1LDE3Mi43LTM4NSwzODVjMCwyMDQuOSwzNDUsNTY4LjgsMzU5LjcsNTg0LjFjNi45LDcuMiwxNi4xLDEwLjksMjUuMywxMC45YzAsMCwwLDAsMCwwYzkuMiwwLDE4LjQtMy42LDI1LjMtMTAuOUM1NDAsOTYzLjgsODg1LDU5OS45LDg4NSwzOTVDODg1LDE4Mi43LDcxMi4zLDEwLDUwMCwxMEw1MDAsMTB6Ii8+PC9nPg0KPC9zdmc+);
    //background-image: icon('maps', $c-black);
  }
}

.location-c__directions-container {
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.5;
  }

}

.location-c__tripAdvisor {

  @media screen and (min-width: $b-desktop) {
    width: 40%;

    & + & {
      padding-left: 4rem;
    }

    &Columns {
      display: flex;
      justify-content: space-evenly;
    }
  }

  dl {
    display: flex;
    flex-wrap: wrap;
  }

  dt {
    width: 40%;
    padding: 3px 0;
  } 

  dd {
    width: 60%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &Rating {
    background-color: $c-gallery;
    
    padding: 5px $s-margin;
    
    margin: 30px -#{$s-margin} $s-margin -#{$s-margin};
    display: flex;
    align-items: center;
    justify-content: space-between;

    &Info {
      font-size: $f-small;
      text-align: end;

      img {
        position: relative;
        right: -10px;
      }
    }

    &Heading {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .rating-item {
    $root: &;

    & + & {
        margin-top: 0.5rem;
    }

    &__label {
        display: block;
    }

    &__score-container {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__bar {
        background-color: rgba($c-mine, 0.15);
        border-radius: 2px;
        height: 10px;
        width: calc(100% - 46px);
    }

    &__bar-value {
        background-color: #00a680;
        border-radius: 2px;
        height: 100%;
    }

    &__score {
        font-weight: bold;
        margin-left: auto;
    }
  }
}