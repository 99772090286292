@import '../../shared/_variables';
@import '../../shared/_icons';

.mapimage__list {
  min-height: 200px;
  height: 60vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &.is-small {
    height: 250px;
  }

  @media screen and (max-width: $b-desktop) {
    + .app-content .linklist__list {
      margin-top: 0;
    }
  }
}

.mapimage__trigger {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mapimage__trigger-icon {
  width: 70px;
  height: 70px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-image: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.4) 40%,
    rgba(0, 0, 0, 0) 70%
  );

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: icon('search-plus', $c-white);
  }

  @media screen and (min-width: $b-desktop) {
    background-position: bottom 15px right;
  }
}

.mapimage__open-in-map-icon {
  width: 70px;
  height: 70px;
  position: absolute;
  right: 0;
  bottom: 0;  

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gU3ZnIFZlY3RvciBJY29ucyA6IGh0dHA6Ly93d3cub25saW5ld2ViZm9udHMuY29tL2ljb24gLS0+DQo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwMCAxMDAwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMDAwIDEwMDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPG1ldGFkYXRhPiBTdmcgVmVjdG9yIEljb25zIDogaHR0cDovL3d3dy5vbmxpbmV3ZWJmb250cy5jb20vaWNvbiA8L21ldGFkYXRhPg0KPGc+PHBhdGggZD0iTTUwMS4yLDI0Ni42YzgxLjgsMCwxNDguNCw2Ni42LDE0OC40LDE0OC40YzAsODEuOC02Ni42LDE0OC40LTE0OC40LDE0OC40Yy04MS44LDAtMTQ4LjQtNjYuNi0xNDguNC0xNDguNEMzNTIuOCwzMTMuMiw0MTkuNCwyNDYuNiw1MDEuMiwyNDYuNiBNNTAxLjIsMTg1Yy0xMTUuOCwwLTIxMCw5NC4yLTIxMCwyMTBjMCwxMTUuOCw5NC4yLDIxMCwyMTAsMjEwYzExNS44LDAsMjEwLTk0LjIsMjEwLTIxMEM3MTEuMiwyNzkuMiw2MTcsMTg1LDUwMS4yLDE4NUw1MDEuMiwxODV6Ii8+PHBhdGggZD0iTTUwMCw3MS42YzE3OC4zLDAsMzIzLjQsMTQ1LjEsMzIzLjQsMzIzLjRjMCwyNS40LTguMiw3MS41LTQ3LjMsMTQ3LjNjLTI3LjQsNTMuMS02NiwxMTMuNC0xMTQuOSwxNzkuMUM1OTcuMiw4MDcuNiw1MzEuNiw4ODEuNSw1MDAsOTE2Yy0zMS42LTM0LjUtOTcuMi0xMDguNC0xNjEuMi0xOTQuNmMtNDguOC02NS43LTg3LjUtMTI2LTExNC45LTE3OS4xYy0zOS4xLTc1LjgtNDcuMy0xMjItNDcuMy0xNDcuM0MxNzYuNiwyMTYuNywzMjEuNyw3MS42LDUwMCw3MS42TDUwMCw3MS42IE01MDAsMTBDNTAwLDEwLDUwMCwxMCw1MDAsMTBjLTIxMi4zLDAtMzg1LDE3Mi43LTM4NSwzODVjMCwyMDQuOSwzNDUsNTY4LjgsMzU5LjcsNTg0LjFjNi45LDcuMiwxNi4xLDEwLjksMjUuMywxMC45YzAsMCwwLDAsMCwwYzkuMiwwLDE4LjQtMy42LDI1LjMtMTAuOUM1NDAsOTYzLjgsODg1LDU5OS45LDg4NSwzOTVDODg1LDE4Mi43LDcxMi4zLDEwLDUwMCwxMEw1MDAsMTB6Ii8+PC9nPg0KPC9zdmc+);
  }

  @media screen and (min-width: $b-desktop) {
    background-position: bottom 15px right;
  }
}