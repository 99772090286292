@import '../../../shared/_variables';

// Activities page - Hero
.activities__hero {
  padding: 40px $s-margin;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  @media screen and (min-width: $b-desktop) {
    order: -1;
    padding: 70px 0;
  }
}

.activities__hero-content {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  color: $c-white;
  text-shadow: 0 0.5px 2px rgba(0,0,0,.8);
}

.activities__hero-title {
  font-size: $f-header;
  font-weight: bold;
  margin-bottom: 10px;
  text-shadow: 0 1px 5px rgba(0,0,0,.8);
}

.activities__hero-description {
  font-weight: bold;
}

// Activities page - Promotion

.activities__promotion {
  padding: 30px $s-margin;
  max-width: 600px;
  margin: 0 auto;
}

.activities__disclaimer {
  padding: 30px $s-margin;
  font-size: $f-medium;
  color: $c-grey;
  max-width: 500px;
  margin: 0 auto;
}