@import '../../shared/_variables';

.secnav-container {
  background-color: $c-white;

  @media screen and (max-width: $b-desktop) {
    position: sticky;
    top: $s-header;
    z-index: 1;
  }
  
  @media screen and (min-width: $b-desktop) {
    border-bottom: 4px solid $c-alabaster;
  }
}

.secnav-content {
  display: flex;
  justify-content: center;

  @media screen and (min-width: $b-desktop) {
    width: 100%;
    max-width: $s-content;
    margin: 0 auto;
    padding: 0;
    justify-content: flex-start;
  }
}

.secnav-link {
  margin: 0 5px;
  padding: 10px;
  color: $c-grey;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 4px solid transparent;
  letter-spacing: 1px;
  transition: opacity 0.25s;
  flex: 1;
  font-size: $f-small;
  text-align: center;
  white-space: nowrap;

  @media screen and (max-width: $b-desktop) {
    min-height: $s-mainnav;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media screen and (min-width: $b-desktop) {
    margin: 0 10px;
    flex: 0;
    font-size: 18px; //$f-normal;
    margin-bottom: -4px;
    text-transform: none;
    color: $c-black;
    padding: 1rem;
    &:hover {
      opacity: 0.5;
    }
  }

  &.is-active {
    color: $c-black;
    border-bottom-color: $c-candlelight;
  }
}
