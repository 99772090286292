@import '../../../shared/_variables';
@import '../../../shared/_icons';

.trip__summary {
  background-color: $c-loafer;
  padding: 20px;
  margin: 40px 0 30px;
  text-align: center;

  h2 {
    margin: 0;
  }

  @media screen and (min-width: $b-desktop) {
    padding: 20px 30px;
    flex: 1;
    margin: 0;
    display: flex;
    flex-direction: row;

  }
}

.trip__summary-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  text-align: left;

  @media screen and (min-width: $b-desktop) {
    justify-content: center;
  }
}

.trip__summary-column {
  & + & {
    margin-top: $s-margin;
  }
  
  @media screen and (min-width: $b-desktop) {
    width: 50%;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;

    & + & {
      margin-left: 40px;
      border-left: 1px solid $c-alto;
      padding-left: 40px;
      margin-top: 0;
    }
  }
}

.trip__summary-paid {
  text-align: center;
  font-size: $f-small;
  margin: 2px 5px;
  padding: 2px 5px;
  border: 1px solid $c-limeade;
  color: $c-limeade;
}

.trip__summary-row {
  display: flex;
  margin-bottom: 10px;
  text-align: left;
}

.trip__summary-label {
  // flex: 3;
}

.trip__summary-value {
  // font-weight: bold;
  // flex: 2;
}

.trip__summary-help {
  color: $c-grey;
  font-size: $f-small;
}

.trip__summary-confirmation {
  color: $c-black;
  font-size: $f-normal;
  font-weight: bold;
  background-color: $c-white;

  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 16px;
    width: 16px;
    margin-left: 7px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: icon('download', $c-black);
  }

  &:hover {
    color: $c-white;

    &:after {
      background-image: icon('download', $c-white);
    }
  }
}

.trip__summary-spacer {
  flex: 2;
  border-bottom: 1px dashed $c-grey;
  margin: 0 5px;
  height: 1rem;
}

.trip__summary-buttons {
  a { 
    margin-top: $s-margin;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  @media screen and (min-width: $b-desktop) {
    flex-grow: 1;

  }
}

.trip__summary-hint {
  font-size: $f-small;
  color: $c-grey;
  margin-top: 10px;
}
