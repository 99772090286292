@import '../../../shared/_variables';
@import '../../../shared/_icons';

.trip__hero {
  background-position: center;
  background-size: cover;
  position: relative;
  color: $c-white;
  padding: 30px 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  > div {
    position: relative;
  }

  @media screen and (min-width: $b-desktop) {
    order: -1;
    padding: 50px 0;
  }
}

.trip__hero-content {
  text-align: center;
  text-shadow: 0 0.5px 2px rgba(0,0,0,.8);

  h1 {
    margin: 0 0 20px;
    text-shadow: 0 1px 5px rgba(0,0,0,.8);

    @media screen and (min-width: $b-desktop) {
      margin: 0;
    }

    span {
      text-shadow: 0 0.5px 2px rgba(0,0,0,.8);
    }
  }

  @media screen and (min-width: $b-desktop) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.trip__hero-title {
  @media screen and (min-width: $b-desktop) {
    text-align: left;
  }
}

.trip__hero-subheading {
  display: block;
  font-size: $f-normal;

  @media screen and (min-width: $b-desktop) {
    display: inline-block;
    font-size: $f-large;
  }
}

.trip__hero-cta {
  text-align: center;
  margin-bottom: -55px;

  &.hide-mobile {
    display: none;
  }

  @media screen and (min-width: $b-desktop) {
    margin-bottom: 0;
    text-align: left;

    &.hide-mobile {
      display: block;
    }
  }
}

.trip__hero-btn {
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 16px;
    width: 16px;
    margin-right: 7px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: icon('lock', $c-white);
  }
}

.trip__hero-ttl {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $s-margin;

  @media screen and (min-width: $b-desktop) {
    margin-top: 0;
    justify-content: flex-end;
  }

  &-number {
    font-size: 3rem;
    margin-right: 10px;
    font-weight: bold;
  }

  &-text {
    text-align: left;
    width: 90px;
    font-size: 18px;
  }
}