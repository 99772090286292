@import '../../../shared/_variables';
@import '../../../shared/_icons';

.trip__notifications {
    max-width: 600px;
    padding-top: $s-margin;
    margin: 0 auto;
    width: 100%;
    
    &-row {
        margin-bottom: $s-margin;
        padding: 0 $s-margin $s-margin $s-margin;
        border-bottom: 1px solid $c-gallery;
    }

    &-row-date {
        color: $c-mine;
        font-size: 0.8rem;
        margin-top: 5px;

        &:before {
            content: '';
            background-image: icon('bell', $c-mine);
            background-size: contain;
            background-repeat: no-repeat;
            width: 0.9rem;
            height: 0.6rem;
            display: inline-block;
          }
    }
}