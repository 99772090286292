$c-white: #ffffff;
$c-alabaster: #f8f8f8;
$c-gallery: #ebebeb;
$c-mercury: #e4e4e4;
$c-alto: #d4d4d4;
$c-dusty: #979797;
$c-grey: #858585;
$c-mine: #3d3d3d;
$c-shaft: #333333;
$c-black: #1e1e1c;

$c-solitude: #e9f7ff;
$c-pacific: #009acd;
$c-lochmara: #0081c6;
$c-congress: #004b85;

$c-loafer: #ecf5e5;
$c-limeade: #47a404;

$c-monza: #d0021b;
$c-thunderbird: #cf1011;

$c-twine: #c4a263;

$c-apricot: #fffdec;
$c-lemon: #fff9cb;
$c-kournikovaapprox: #ffec78;
$c-candlelight: #ffdc0d;
$c-turbo: #ffec00;

$c-link: $c-pacific;
$c-bg: $c-white;
$c-text: $c-black;

$s-mainnav: 50px;
$s-header: 50px;
$s-margin: 15px;
$s-margin-small: 7px;
$s-content: 1000px;
$s-radius: 3px;

$f-small: 12px;
$f-medium: 14px;
$f-normal: 16px;
$f-large: 20px;
$f-header: 2rem;

$b-desktop: 1200px;
