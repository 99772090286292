@import '../../shared/_variables';

.app-content {
  width: 100%;
  max-width: $s-content;
  margin: 0 auto;
  padding: 0 $s-margin;

  &.no-padding {
    padding: 0;
    margin-bottom: 30px;
  }

  @media screen and (min-width: $b-desktop) {
    padding: 0;
  }
}

.btn {
  color: $c-white;
  background: $c-limeade;
  padding: 10px 30px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.25s;

  @media screen and (min-width: $b-desktop) {
    display: inline-flex;
    padding: 10px 50px;
    border-radius: $s-radius;

    &:hover {
      background-color: $c-mine;
    }
  }

  &--alert {
    background-color: $c-monza;

    @media screen and (min-width: $b-desktop) {
      &:hover {
        background-color: $c-mine;
      }
    }
  }

  &--transparent {
    background-color: transparent;
    color: $c-pacific;
    border: 1px solid $c-mine;

    @media screen and (min-width: $b-desktop) {
      &:hover {
        background-color: $c-mine;
      }
    }
  }
}

h1 {
  font-size: $f-header;
}
