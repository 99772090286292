@import '../../../shared/_variables';
@import '../../../shared/_icons';

.SkiingWebcam {
    &__iframe {
        width: 100%;
        min-height: 70vw;
        border: 0;
        margin-top: $s-margin;
      }
}